
<div class="monitor-listing-pane flex flex-column gap-2 overflow-x-auto" [ngClass]="{'reduced': sidebarVisible}">

    <p-menubar class="menu">
        <ng-template pTemplate="start">
            <span class="text-500-20px">Monitors</span>
        </ng-template>
        <ng-template pTemplate="end">
            <p-button label="New Monitor" (onClick)="navigateToCreateMonitor()" icon="pi pi-plus"></p-button>
        </ng-template>
    </p-menubar>
    <p-menubar class="menu">
        <ng-template pTemplate="start" >
            <div class="flex align-items-center gap-3">
                <input type="search" class="border-round-xl w-16rem" placeholder="Search" pInputText [(ngModel)]="searchInputText" (input)="onSearchChange()" />
                <span class="text-xl border-left-1 pl-4 ml-4 p-1">Quick Filter</span>
                <div class="card flex justify-content-center ml-4">
                  <p-select
                    class=""
                    [disabled]="true"
                    [options]="groups"
                    [(ngModel)]="selectedGroup"
                    optionLabel="name"
                    placeholder="Group" />
                </div>
              <div class="card flex justify-content-center ml-4">
                <p-select
                  [options]="types"
                  [(ngModel)]="selectedType"
                  optionLabel="name"
                  [showClear]="true"
                  (ngModelChange)="onTypeSelect($event)"
                  placeholder="Type" />

              </div>
              <div class="card flex justify-content-center ml-4">
                <p-select
                  [options]="projects"
                  [(ngModel)]="selectedProject"
                  optionLabel="name"
                  [showClear]="!!selectedProject"
                  placeholder="Projects"
                  (ngModelChange)="onProjectSelect($event)"
                ></p-select>

              </div>
            </div>
        </ng-template>
        <ng-template pTemplate="end">
          <div class="flex gap-2">
            @if(selectedViewOption === 'table'){
              <p-multiselect  display="chip" [options]="columns" (onChange)="saveViewOption($event, 'columns')" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columns selected" [style]="{ 'min-width': '200px' }" placeholder="Choose Columns" />
            }
            <p-selectbutton [options]="viewOptions" (onChange)="saveViewOption($event, 'view')" [(ngModel)]="selectedViewOption" optionValue="value" aria-labelledby="basic" >
              <ng-template #item let-item>
                <i [class]="item.icon"></i>
              </ng-template>
            </p-selectbutton>
          </div>
        </ng-template>
    </p-menubar>
    <div>
      @if (loading){
        @for (skeleton of totalSkeletons; track skeleton['id']){
          <div class="flex flex-row gap-0 generic-card overflow-x-auto">
            <div class="w-full flex flex-row">
              <p-skeleton shape="circle" size="3rem" styleClass="mr-2" />
              <div class="w-full">
                <p-skeleton height="2rem" width="15rem" styleClass="mb-2" />
                <p-skeleton width="8rem" styleClass="mb-2" />
              </div>
            </div>
            <div class="w-full flex flex-column align-items-end">
              <p-skeleton height="2rem" width="5rem" styleClass="mb-2" />
              <p-skeleton width="8rem" styleClass="mb-2" />
            </div>
            </div>
        }


      }
      @if (monitors.length>0) {
        <div class="m-2">
          @if(selectedViewOption === 'list'){
            @for(monitor of filteredMonitors; track monitor.id){
              <app-monitor-card view="list" [monitor]="monitor" [sidebarVisible]="sidebarVisible" (updatedMonitorEvent)="updatedMonitorEvent($event)"></app-monitor-card>
            }
          } @else {
            <p-table
            [value]="filteredMonitors"
            [tableStyle]="{ 'min-width': '50rem' }"
            [lazy]="true"
            size="small"
            selectionMode="single"
            (onRowSelect)="onRowSelect($event)"
            resizableColumns="true"
            scrollable="true"
            [columns]="selectedColumns"
            class="p-datatable-sm">

            <ng-template pTemplate="header">
              <tr >
                @for(col of selectedColumns;track col){
                  <th pResizableColumn>{{col.header}}</th>
                }
                <th pResizableColumn>Status</th>
                <th pResizableColumn>Type</th>
                <th pResizableColumn>Active</th>
                <th pResizableColumn>Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-monitor>
              <tr >
                @for(col of selectedColumns; track col){
                  <td [pSelectableRow]="monitor" class="text-500-16px">
                    {{ col.field === 'scheduleInterval' ? monitor[col.field].replace('_', ' ').toLowerCase() :
                       col.field === 'monitorStatusCheckedAt' ? (monitor[col.field] | date) : monitor[col.field] }}
                  </td>
                }
                <td [pSelectableRow]="monitor"  >
                  @switch (monitor.status){
                    @case (MonitorStatus.UP){
                      <svg pTooltip="UP since last check: {{monitor.monitorStatusCheckedAt | date: 'dd-MMM HH:mm'}}" width="15" height="15" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-text">
                        <path [ngClass]="{'svg-bgray': !monitor.active}" d="M8.81793 0.230316L15.9159 11.1961C15.9663 11.274 15.9951 11.3645 15.9995 11.458C16.0038 11.5514 15.9835 11.6444 15.9406 11.7269C15.8977 11.8094 15.8339 11.8783 15.756 11.9264C15.678 11.9745 15.5889 12 15.498 12L1.30205 12C1.2112 12 1.12206 11.9745 1.04413 11.9264C0.966204 11.8783 0.9024 11.8094 0.859518 11.7269C0.816636 11.6444 0.796281 11.5514 0.800623 11.458C0.804964 11.3645 0.833838 11.274 0.88417 11.1961L7.98217 0.230316C8.02803 0.159467 8.09017 0.101376 8.16307 0.0611989C8.23597 0.0210215 8.31737 -3.28593e-07 8.40005 -3.32207e-07C8.48273 -3.35821e-07 8.56413 0.0210215 8.63703 0.0611988C8.70992 0.101376 8.77207 0.159467 8.81793 0.230316Z" fill="#15803D"/>
                      </svg>
                    }
                    @case (MonitorStatus.DOWN){
                      <svg pTooltip="Issues to address!" width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-text">
                        <path d="M8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.4954 12.1402 12.1402 15.4954 8 15.5ZM7.25 10.25V11.75H8.75V10.25H7.25ZM7.25 4.25V8.75H8.75V4.25H7.25Z" fill="#EF4444" [ngClass]="{'svg-bgray':!monitor.active}"/>
                      </svg>
                    }
                  }
                </td>
                <td [pSelectableRow]="monitor"  >
                  <p-tag severity="secondary" value="{{monitor.monitorType.split('_')[0].toLowerCase()}}" />
                </td>
                <td [pSelectableRow]="monitor"  class="pr-2">
                  @if (!monitor.paused){
                    <i pTooltip="Running" class="pi pi-play-circle cursor-text" style="color: green; font-size: 1.2rem;"></i>
                  } @else {
                    <i pTooltip="Paused" class="pi pi-pause-circle cursor-text" style="color: gray; font-size: 1.2rem;"></i>
                  }
                </td>
                <td>
                <app-monitor-card
                  view="table"
                  [monitor]="monitor"
                  [sidebarVisible]="sidebarVisible"
                  (updatedMonitorEvent)="updatedMonitorEvent($event)">
                </app-monitor-card>
                </td>
              </tr>
            </ng-template>
          </p-table>
          }
        </div>
      } @else if (monitors.length==0 && !loading){
        <div style="text-align: center; margin-top: 4%">
          <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_814_160)">
              <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_814_160">
                <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg></div>
          <div class=" flex justify-content-center text-2xl my-2">
            No Data Found
          </div>
          <div class="info-2 flex justify-content-center text-xl my-2">
            Add monitor or adjust filter and try again
          </div>

        </div>
      }

    </div>
</div>
