import { Component, inject, OnInit } from '@angular/core';
import { ProgressSpinner } from 'primeng/progressspinner';
import { Toast } from 'primeng/toast';
import {DatePipe, NgClass, NgIf} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { InputText } from 'primeng/inputtext';
import { Menubar } from 'primeng/menubar';
import { MenuItem, MessageService, PrimeTemplate } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SidebarStateService } from '../../services/shared/sidebar/sidebar-state.service';
import { Router } from '@angular/router';
import { ProjectService } from '../../services/projectService';
import { ProjectsResponse } from '../../models/project/projects-response';
import { TableModule } from 'primeng/table';
import { Button, ButtonDirective } from 'primeng/button';
import { Menu } from 'primeng/menu';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Dialog} from "primeng/dialog";
import {Textarea} from "primeng/textarea";
import {SkeletonTableComponent} from "../../components/common/skeleton-table/skeleton-table.component";


@Component({
  selector: 'app-projects',
  standalone: true,
    imports: [
        ProgressSpinner,
        Toast,
        NgClass,
        FormsModule,
        InputText,
        Menubar,
        PrimeTemplate,
        DatePipe,
        TableModule,
        Button,
        ButtonDirective,
        Menu,
        Dialog,
        ReactiveFormsModule,
        Textarea,
        SkeletonTableComponent
    ],
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  sidebarVisible !: boolean;
  loading: boolean = false;
  searchInputText: string = '';
  menuItems: MenuItem[] = [];
  private sidebarSubscription!: Subscription;
  private sidebarState : SidebarStateService = inject(SidebarStateService);
  private router: Router = inject(Router);
  private projectService: ProjectService = inject(ProjectService);
  private messageService: MessageService = inject(MessageService);

  projects: ProjectsResponse[] = [];
  showModal = false; // To control visibility of modal
  isEditProject = false; // To differentiate between edit and create mode
  projectForm: FormGroup;  // Form group for the project form
  private editProject!: ProjectsResponse;
  projectsHeader: string[] = []

  constructor(private fb: FormBuilder) {
    // Initialize the form with necessary validations
    this.projectForm = this.fb.group({
      id: null,
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    );
    this.projectsHeader = ['Id', 'Project Name', 'Description','Created Date','Updated Date','Actions'];
    this.loadProjects();
  }

  loadProjects(): void {
    this.loading = true;
    this.projectService.getAll().subscribe({
      next: (res) => {
        console.log('Loaded projects:', res);
        this.projects = res;
      },
      error: (err) => {
        console.error(err);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error loading projects!',
        });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }


  navigateToCreateProject() {
    this.showModal = true;
    this.isEditProject = false;
    this.projectForm.reset();
  }


  showMenu(event: MouseEvent, menu: any, project: any): void {
    console.log("Project object:", project);
    console.log("Project ID:", project?.id);

    if (!project?.id) {
      console.error('Error: Invalid project ID');
      return;
    }

    this.menuItems = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.openEditProjectModal(project),
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {},
      },
    ];

    menu.toggle(event);
  }


  openEditProjectModal(project: any): void {
    debugger
    this.isEditProject = true;
    this.showModal = true;

    // Fetch project data by ID and populate the form
    this.projectService.getProjectById(project.id).subscribe({
      next: (data) => {
        this.editProject = data;
      },
      error: (err) => {
        console.error('Error fetching project details:', err);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error fetching project details!',
        });
        this.showModal = false;
      },
      complete: () => {
        this.projectForm.setValue({
          id: this.editProject.id,
          name: this.editProject.name,
          description: this.editProject.description
        });
      }
    });
  }

  saveProject() {
    if (this.projectForm.invalid) {
      return;
    }

    const projectData = this.projectForm.value;

    if (this.isEditProject) {
      const projectId = this.projectForm.value.id;
      if (!projectId) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Invalid project ID',
        });
        return;
      }

      this.projectService.updateProject(projectId, projectData).subscribe({
        next: (res) => {
          console.log('Project updated successfully:', res);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Project updated successfully!',
          });
          this.loadProjects();
        },
        error: (err) => {
          console.error('Error updating project:', err);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error updating project!',
          });
        },
        complete: () => {
          this.showModal = false;
        },
      });
    } else {
      this.projectService.createProject(projectData).subscribe({
        next: (res) => {
          console.log('Project created successfully:', res);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Project created successfully!',
          });
          this.loadProjects();
        },
        error: (err) => {
          console.error('Error creating project:', err);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error creating project!',
          });
        },
        complete: () => {
          this.showModal = false;
        },
      });
    }
  }

  closeModal() {
    this.showModal = false;
  }
}
