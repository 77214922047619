import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.dev';
import {Observable} from 'rxjs';
import {PaginatedResponse} from '../../models/commons';
import {KeycloakProfile, UserModel} from '../../models/users/users-response';


@Injectable({
  providedIn: "root"
})

export class UsersService {
  constructor(private httpClient: HttpClient) {
  }

  addUser(payload: any): Observable<any> {
    return this.httpClient.post(`${environment.baseUri}/user`, payload)
  }

  updateUser(userId: number | null, payload: any): Observable<any> {
    return this.httpClient.put(`${environment.baseUri}/user/${userId}`, payload)
  }

  getAllUsers(page: number, size: number, query: string): Observable<PaginatedResponse<UserModel>> {
    return this.httpClient.get<PaginatedResponse<UserModel>>(
      environment.baseUri + '/users', {
        params: new HttpParams()
          .set('page', page.toString())
          .set('size', size.toString())
          .set('query', query)
      }
    );
  }

  getSingleUser(userId: number): Observable<any> {
    return this.httpClient.get(`${environment.baseUri}/user/${userId}`)
  }

  deleteUser(userId: number): Observable<any> {
    return this.httpClient.delete(`${environment.baseUri}/user/${userId}`);
  }

  inactiveUser(userId: number): Observable<any> {
    return this.httpClient.delete(`${environment.baseUri}/user/${userId}/soft-delete`);
  }

  activeUser(userId: number, payload: any): Observable<any> {
    return this.httpClient.put(`${environment.baseUri}/user/${userId}/undo`, payload)
  }

  getUserProfile(token: string): Observable<KeycloakProfile> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    });

    return this.httpClient.get<KeycloakProfile>(`${environment.keycloak.keycloakUrl}/realms/${environment.keycloak.realm}/protocol/openid-connect/userinfo`, {headers});
  }

}
