import {Component, OnInit, ViewChild} from '@angular/core';
import {TableModule} from 'primeng/table';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {Button, ButtonDirective} from 'primeng/button';
import {InputText} from 'primeng/inputtext';
import {debounceTime, distinctUntilChanged, Subject, Subscription, switchMap} from 'rxjs';
import {UsersService} from '../../services/users/users.service';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {UserModel} from '../../models/users/users-response';
import {Menubar} from 'primeng/menubar';
import {NgClass} from '@angular/common';
import {Menu} from 'primeng/menu';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {ContextMenu} from 'primeng/contextmenu';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {Toast} from 'primeng/toast';
import {Router} from '@angular/router';
import {ToggleSwitch} from 'primeng/toggleswitch';
import {Page} from '../../models/commons';
import {SkeletonTableComponent} from "../../components/common/skeleton-table/skeleton-table.component";

@Component({
  selector: 'app-user-menu',
  standalone: true,
    imports: [
        TableModule,
        FormsModule,
        DropdownModule,
        ButtonDirective,
        InputText,
        Button,
        Menubar,
        NgClass,
        Menu,
        ConfirmDialog,
        Toast,
        ToggleSwitch,
        SkeletonTableComponent
    ],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class UserMenuComponent implements OnInit {
  @ViewChild('cm') cm: ContextMenu | undefined;

  users: UserModel[] = [];
  page: Page = { size: 10, totalElements: 0, totalPages: 0, number: 0 };
  searchQuery: string = '';
  searchSubject = new Subject<string>();
  sidebarVisible !: boolean;
  isLoading: boolean = false
  sidebarSubscription !: Subscription;
  loading = true;
  userHeader: string[] = []
  menuItems: MenuItem[] = [];
  selectedRecord: any;

  constructor(private userService: UsersService,
              private sidebarState: SidebarStateService,
              private router:Router,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.userHeader = ['Id', 'Name', 'User Name','Email','Mobile','Role','Status','Action'];

    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.searchSubject.pipe(
      debounceTime(300),               // Wait for 300ms after typing
      distinctUntilChanged(),          // Only trigger when the search text actually changes
      switchMap(query =>  this.userService.getAllUsers(0, this.page.size, query))  // Switch to the API call
    ).subscribe((value) => {
      const {content, page} = value;
      this.users = content;
      this.page = page;
    });
    this.loadUsers({page: 0, size: this.page.size});
  }

  loadUsers(event: any): void {
    this.isLoading = true

    const pageIndex = event.first ? event.first / event.rows : 0;
    const pageSize = event.rows || this.page.size;
    const searchQuery = this.searchQuery || '';

    console.log(`Loading users: query="${searchQuery}", page=${pageIndex}, size=${pageSize}`);

    this.userService.getAllUsers(pageIndex, pageSize, searchQuery).subscribe({
      next: response => {
        this.isLoading = false
        const {content, page} = response;
        this.users = content;
        this.page = page;
        this.loading=false;

      },
      error: err => {
        this.isLoading = false
        console.log("Unknown error fetching users: ", err)
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error creating user'})
      }

    })
  }

  deleteUser(userId: number): void {
    console.log("Deleting user: ", userId);
    this.userService.deleteUser(userId).subscribe({
      next: value => {
        console.log("User deleted!");
        this.messageService.add({severity: 'info', summary: 'Confirmed', detail: 'User deleted!'});
      },
      error: err => {
        console.log("Unknown error in deleting user: ", userId);
        this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'Something bad happened!'});
      }
    })
  }

  onPageChange(event: any): void {
    console.log("changing page: ", event.first, ",", event.rows)
    this.loadUsers(event);
  }

  onRowContextMenu(event: MouseEvent) {
    // event.preventDefault();  // Prevent the default browser context menu
    if (this.cm) {
      console.log(event);
      this.cm.show({x: 1300, y: 225});
    }
  }

  // Trigger the menu for the clicked row
  showMenu(event: Event, menu: any, record: any) {
    this.selectedRecord = record; // Bind the selected row data
    this.menuItems = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.editRecord(this.selectedRecord),
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.deleteRecord(this.selectedRecord),
      },
    ];
    console.log("mouse event", event);
    menu.toggle(event); // Show the menu
  }

  editRecord(record: any) {
    console.log('Edit record:', record);
    this.router.navigate(['/users/update',record.id])
    // Implement your edit logic here
  }

  deleteRecord(record: any) {
    console.log('Delete record:', record);
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      rejectLabel: 'Cancel',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Delete',
        severity: 'danger',
      },
      accept: () => {
        console.log("Deleting user with id:", record.id);
        this.deleteUser(record.id);
      },
      reject: () => {
        this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
      },
    });
  }

  navigateToAddUser() {
    this.router.navigate(['/users/create'])
  }

  onSearchChange() {
    this.searchSubject.next(this.searchQuery);
  }

  toggleUserState(user: any): void {
    const payload={
      "enabled": true,
    }
    const apiCall = user.enabled
      ? this.userService.activeUser(user.id,payload)
      : this.userService.inactiveUser(user.id);

    apiCall.subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `User ${user.enabled ? 'activated' : 'deactivated'} successfully.`,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to ${user.enabled ? 'activate' : 'deactivate'} the user.`,
        });
        user.enabled = !user.enabled;
      },
    });
  }

}
