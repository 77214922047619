<p-toast />
<p-menu #menu [model]="items" [popup]="true" appendTo="body">
  <ng-template #item let-item>
    @if(item.template === 'userInfoName'){
      <div class="p-menuitem p-link p-0">
        <div class="flex items-center gap-3 p-2 m-1">
          <span class="pi pi-user"></span>
          <span class="font-bold">{{ username }}</span>
        </div>
      </div>
    }

    @if(item.template === 'userInfoEmail'){
      <div class="p-menuitem p-link p-0">
        <div class="flex items-center gap-3 p-2 m-1">
          <span class="pi pi-envelope"></span>
          <span>{{ email }}</span>
        </div>
      </div>
    }

    @if(item.label === 'Slider'){
      <div class="p-2 mt-2">
        <div class="flex align-items-center justify-content-between mb-3">
          <span class="text-xs font-medium">-</span>
          <span class="align-self-center text-xs mx-auto">Current Size: {{value}}</span>
          <span class="text-sm align-self-end font-medium">+</span>
        </div>
        <p-slider
          min="10"
          max="14"
          [(ngModel)]="value"
          class="text-sm"
          styleClass="w-full"
          (onSlideEnd)="setAppSize($event)"
        ></p-slider>
      </div>
    } @else {
      <a pRipple class="flex items-center p-menu-item-link">
        <span [class]="item.icon"></span>
        <span class="ml-2">{{ item.label }}</span>
        <p-badge *ngIf="item.badge" class="ml-auto" [value]="item.badge" />
        <span *ngIf="item.shortcut" class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1">
                {{ item.shortcut }}
            </span>
      </a>
    }

  </ng-template>
</p-menu>
<div #navbar class="navbar" [ngClass]="{'reduced': sidebarVisible}">
    <p-menubar >
        <ng-template pTemplate="start">
            <p-button [text]="true" (onClick)="toggleSidebar()" icon="pi pi-align-justify" />
        </ng-template>
        <ng-template pTemplate="end">
            <div class="flex align-items-center gap-5">
                <i class="pi pi-sun border-1 border-indigo-50 cursor-pointer p-2 border-round-3xl" (click)="toggleDarkTheme()"></i>

<!--              <p-avatar class="cursor-pointer" (click)="openMenu(menu, $event)" image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" shape="circle" />-->
              <p-overlay-badge value="0" severity="danger" class="inline-flex">
                <p-avatar class="cursor-pointer" (click)="openMenu(menu, $event)" label="{{ getInitials(user?.name || '') }}" shape="circle" />
              </p-overlay-badge>

            </div>
        </ng-template>
    </p-menubar>
</div>
