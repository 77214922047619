
import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {NgClass} from '@angular/common';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {Button, ButtonDirective} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccordionModule} from 'primeng/accordion';
import {ChipModule} from 'primeng/chip';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {InputText} from 'primeng/inputtext';
import {IntegrationResponse} from '../../models/integrations/integration-response';
import {IntegrationService} from '../../services/integrations/integration.service';
import {Select} from 'primeng/select';
import {MultiSelect} from 'primeng/multiselect';
import {AlertGroupService} from '../../services/alert-group/alert-group.service';
import {AlertGroupPayload} from '../../models/alert-group/alert-group-payload';
import {
  CreateEmailIntegrationComponent
} from '../../components/create-email-integration/create-email-integration.component';
import {
  CreateSlackIntegrationComponent
} from '../../components/create-slack-integration/create-slack-integration.component';
import {AlertGroupResponse} from '../../models/alert-group/alert-group-response';
import {StatusRequest} from '../../models/status-request';

// noinspection TypeScriptValidateTypes
@Component({
  selector: 'app-create-alert-group',
  standalone: true,
  imports: [
    NgClass,
    MenuModule,
    MenubarModule,
    Button,
    CardModule,
    FormsModule,
    ChipModule,
    AccordionModule,
    ChipModule,
    ConfirmDialogModule,
    ToastModule,
    InputText,
    ButtonDirective,
    Select,
    MultiSelect,
    ReactiveFormsModule,
    CreateEmailIntegrationComponent,
    CreateSlackIntegrationComponent
  ],
  templateUrl: './create-alert-group.component.html',
  styleUrl: './create-alert-group.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class CreateAlertGroupComponent implements OnInit{
  sideBarVisible: boolean = false;
  loading: boolean = false;
  private subscription!: Subscription;
  private router: Router = inject(Router);
  private sidebarState: SidebarStateService = inject(SidebarStateService);
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  private integrationService: IntegrationService = inject(IntegrationService);
  private messageService: MessageService = inject(MessageService);
  private alertGroupService: AlertGroupService = inject(AlertGroupService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private fb: FormBuilder = inject(FormBuilder);

  emailIntegrations: IntegrationResponse[] = []
  slackIntegrations: IntegrationResponse[] = []
  selectedSlackIntegration: IntegrationResponse[]=[];
  selectedEmailIntegration: IntegrationResponse[]=[];
  selectedIntegration!: string;

  integrations: IntegrationResponse[] = []

  alertId: string | any;
  alertGroupForm: FormGroup | any;

  ngOnInit(): void {
    this.loading = true;
    this.alertGroupForm = this.fb.group({
      name: ['', Validators.required],
      description: ['']
    });
    this.subscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sideBarVisible = visible;
      }
    );
    this.loadIntegrations();
    this.loading = false;
    console.log(this.selectedEmailIntegration,'selectedEmailIntegration')

    this.route.queryParams.subscribe(params => {
      this.alertId = params['alertId'];
      console.log(this.alertId,'id')

      if (this.alertId) {
        this.getAlertDetailsById();
      }
    });
  }

  loadIntegrations() {
    this.integrationService.getAll().subscribe({
      next: (res) => {
        this.emailIntegrations = res.content.filter(item => item.type === 'Email');
        this.slackIntegrations = res.content.filter(item => item.type === 'Slack');

        if (this.alertId) {
          this.selectedEmailIntegration = this.emailIntegrations.filter(emailIntegration =>
            this.selectedEmailIntegration.some(selected => selected.id === emailIntegration.id)
          );
          this.selectedSlackIntegration = this.slackIntegrations.filter(slackIntegration =>
            this.selectedSlackIntegration.some(selected => selected.id === slackIntegration.id)
          );
        }

        console.log(this.emailIntegrations, 'Filtered Email Integrations');
        console.log(this.slackIntegrations, 'Filtered Slack Integrations');
      },
      error: (error) => {
        console.error(error);
      }
    });
  }



  navigateAway(){
    if(this.alertGroupForm.touched){
      this.confirmationService.confirm({
        message: '<div class="flex flex-column justify-content-center align-items-center"><span>Are you sure?</span><span>Your changes to the form will be lost!</span></div>',
        icon: 'pi pi-info-circle',
        header: 'Confirmation',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.backToAlertsPage();
        },
        reject: () => {},
        key: 'positionDialog'
      })
    } else {
      this.backToAlertsPage();
    }
  }

  backToAlertsPage() {
    this.router.navigate(['/alerts']);
  }


  submitForm() {
    const emailIntegrationIds = this.selectedEmailIntegration.map(
      (integration: IntegrationResponse) => integration.id
    );
    const slackIntegrationIds = this.selectedSlackIntegration.map(
      (integration: IntegrationResponse) => integration.id
    );

    const selectedIntegrationIds = [...emailIntegrationIds, ...slackIntegrationIds];
    if (this.alertGroupForm.valid) {
      const payload = this.alertGroupForm.value;
      const updatedPayload = {
        ...payload,
        integrationIds: selectedIntegrationIds,
      };
      if (this.alertId) {
        this.alertGroupService.updateAlertGroup(this.alertId, updatedPayload).subscribe(
          (response) => {
            console.log('Alert Group updated successfully:', response);
          },
          (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update Alert Group. Please try again'});
          },
          () => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Alert Group Updated Successfully!'});
            setTimeout(() => {
              this.backToAlertsPage();
            },1000)

          }
        );
      } else {
        this.alertGroupService.create(updatedPayload).subscribe({
          next: (res) => {
            const alertId = res.id;
            const alertName = res.name;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Alert Group created successfully!',
            });
            this.backToAlertsPage();
          },
          error: (error) => {
            console.error(error);
            this.loading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error creating alert group',
            });
          },
          complete: () => {
            setTimeout(() => {
              this.alertGroupForm.reset();
              this.loading = false;
            }, 1000);
          },
        });
      }
    } else {
      alert('Form is invalid. Please check the fields and try again.');
    }
  }

  getAlertDetailsById() {
    this.alertGroupService.getAlertGroupById(this.alertId).subscribe(
      (response: AlertGroupResponse) => {
        this.alertGroupForm.patchValue({
          name: response.name,
          description: response.description
        });

        if (response.integrations && response.integrations.length > 0) {
          this.selectedEmailIntegration = response.integrations.filter(
            (integration: IntegrationResponse) => integration.type === 'Email'
          );

          this.selectedSlackIntegration = response.integrations.filter(
            (integration: IntegrationResponse) => integration.type === 'Slack'
          );
        }
      },
      (error) => {
        console.error('Error fetching alert group details:', error);
      }
    );
  }


  newEmailIntegrationCreated($event: IntegrationResponse) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Email integration created successfully!' });
    this.loadIntegrations();
  }

  setIntegrationType(integration: string) {
    this.selectedIntegration = integration;
  }
  removeEmailIntegrations(){
    this.selectedEmailIntegration=[]
  }
  removeSlackIntegrations(){
    this.selectedSlackIntegration=[]
  }
}
