import {Pipe, PipeTransform} from '@angular/core';
import {Monitor, StatusPageMonitorResponse} from '../../../models/monitors/monitor-responses';

@Pipe({
  name: 'statusPageMonitorConverter',
  standalone: true
})
export class StatusPageMonitorConverterPipe implements PipeTransform {

  transform(value: Monitor): StatusPageMonitorResponse {
    return {
      httpUrl: value.httpUrl, id: value.id, name: value.name, rank: -1
    };
  }

}
