import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {InputText} from 'primeng/inputtext';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ExpiryThreshold} from '../../../models/enums';
import {Button} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButton} from 'primeng/selectbutton';
import {ValidationService} from '../../../services/shared/validation/validation.service';
import { MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { SslMonitorService } from '../../../services/monitor/ssl/ssl-monitor.service';
import { Router } from '@angular/router';
import {ProgressSpinner} from 'primeng/progressspinner';
import { SslMonitorResponse } from '../../../models/monitors/monitor-responses';
import { SslMonitorPayload } from '../../../models/monitors/monitor-requests';
import {ProjectRequest} from '../../../models/project/project-request';
import {MonitorService} from '../../../services/monitor/monitor.service';

@Component({
  selector: 'app-create-ssl-monitor',
  standalone: true,
  imports: [
    InputText,
    ReactiveFormsModule,
    Button,
    DropdownModule,
    SelectButton,
    Toast,
    ProgressSpinner
  ],
  templateUrl: './create-ssl-monitor.component.html',
  styleUrl: './create-ssl-monitor.component.scss'
})
export class CreateSslMonitorComponent implements OnInit {

  private customValidator = inject(ValidationService);
  private messageService = inject(MessageService);
  private sslMonitorService: SslMonitorService = inject(SslMonitorService);
  private monitorService: MonitorService = inject(MonitorService);
  private router = inject(Router)
  loading: boolean = false
  @Input() selectedMonitorForEditId!: number;
  @Input() selectedProject!: ProjectRequest;
  @Input() editMode: boolean = false;
  private selectedMonitorForEdit!: SslMonitorResponse;

  sslPayloadForm: FormGroup = new FormGroup({
    httpUrl: new FormControl('', this.customValidator.urlValidator),
    name: new FormControl('', Validators.required),
    expiryThreshold: new FormControl('', Validators.required),
    group: new FormControl(''),
    labelIds: new FormControl([]),
    alertGroupIds: new FormControl([])
  });


  @Output() alertGroupIdsEvent: EventEmitter<number[]> = new EventEmitter<number[]>();
  groups: {}[] = [{name:'CarviaTech', id:0}, {name:'Dodda', id:1}, {name:'Survey Of India', id:2}];
  certificateExpiryThreshold = [
    {label: '30 days', value: ExpiryThreshold['30 days']},
    {label: '20 days', value: ExpiryThreshold['20 days']},
    {label: '10 days', value: ExpiryThreshold['10 days']},
    {label: '7 days', value: ExpiryThreshold['7 days']},
    {label: '2 days', value: ExpiryThreshold['2 days']},
  ]

  ngOnInit(): void {
    this.loadEditForm();
  }

  loadEditForm() {
      if (this.editMode && this.selectedMonitorForEditId) {
        this.loading = true;
        this.sslMonitorService.get(this.selectedMonitorForEditId).subscribe({
          next: (res) => {
            this.selectedMonitorForEdit = res
            this.selectedProject=res.project;
          },
          error: (err) => {
            this.loading = false;
            console.error('Err editing monitor', err);
          },
          complete: () => {
            this.loading = false;
            this.sslPayloadForm.controls['httpUrl'].setValue(this.selectedMonitorForEdit.httpUrl);
            this.sslPayloadForm.controls['name'].setValue(this.selectedMonitorForEdit.name);
            this.sslPayloadForm.controls['expiryThreshold'].setValue(this.selectedMonitorForEdit.expiryThreshold)
            this.monitorService.setSelectedProject(this.selectedMonitorForEdit.project);
            const alertGroupIds = this.selectedMonitorForEdit.alertGroups.map(group => group.id);
            this.sslPayloadForm.controls['alertGroupIds'].setValue(alertGroupIds);
            this.alertGroupIdsEvent.emit(alertGroupIds)
          }
        })
      }
  }

  submitForm(alertGroupIds: number[],selectedProject: any){
    this.loading = true;
    this.sslPayloadForm.controls['alertGroupIds'].setValue(alertGroupIds);
    if(!this.sslPayloadForm.valid){
      this.sslPayloadForm.markAllAsTouched();
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Form contain errors!' });
      return;
    }
    const projectId = selectedProject?.id;
    if(this.editMode){
      this.updateSslMonitor(projectId);
    } else {
      this.createSslMonitor(projectId);
    }
  }

  updateSslMonitor(projectId:string) {
    const payload: SslMonitorPayload = this.sslPayloadForm.getRawValue();
    this.sslMonitorService.update(this.selectedMonitorForEditId,payload,projectId).subscribe({
      next: (res) => {
        console.log('res', res);
      },
      error:(error) => {
        console.error('error', error)
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating SSL monitor' });
      },
      complete: () => {
        console.log('complete')
        this.sslPayloadForm.reset();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Monitor Updated Successfully!'});
        setTimeout(() => {
          this.loading = false;
          this.router.navigate(['/monitors']);
        }, 1000);
      }
    });
  }

  createSslMonitor(projectId: string) {
      const payload: SslMonitorPayload = this.sslPayloadForm.getRawValue();
      this.sslMonitorService.create(payload,projectId).subscribe({
        next: (res) => {
          console.log('res', res);
        },
        error:(error) => {
          console.error('error', error)
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating SSL monitor' });
        },
        complete: () => {
          console.log('complete')
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Monitor Created Successfully!'});
          setTimeout(() => {
            this.loading = false;
            this.sslPayloadForm.reset();
            this.router.navigate(['/monitors']);
          }, 1000);
        }
    });
  }

}
