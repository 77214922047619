import {Routes} from '@angular/router';
import {MonitorsComponent} from './pages/monitors/monitors.component';
import {CreateMonitorComponent} from './pages/create-monitor/create-monitor.component';
import {LoginComponent} from './login/login/login.component';
import {StatusPageComponent} from './pages/status-page/status-page.component';
import {CreateStatusComponent} from './pages/create-status/create-status.component';
import {EditMonitorStatusComponent} from './pages/edit-monitor-status/edit-monitor-status.component';
import {AlertListingComponent} from './pages/alert-listing/alert-listing.component';
import {CreateAlertGroupComponent} from './pages/create-alert-group/create-alert-group.component';
import {IntegrationsComponent} from './pages/integrations/integrations.component';
import {UserMenuComponent} from './pages/user-menu/user-menu.component';
import {CreateUserComponent} from './components/create-user/create-user.component';
import {SlackOauthComponent} from './login/integrations/slack/slack-oauth/slack-oauth.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {MonitorsDetailComponent} from './pages/monitors-detail/monitors-detail.component';
import {EndUserStatusPageComponent} from './pages/end-user-status-page/end-user-status-page.component';
import {IncidentsComponent} from './pages/incidents/incidents.component';
import {ProjectsComponent} from './pages/projects/projects.component';
import { ActionsComponent } from './actions/actions/actions.component';



export const routes: Routes = [

  {path: 'login', component: LoginComponent, title: 'login'},
  // {path: 'alerts',component:AlertComponent, title: 'Alerts'},
  {path: 'dashboard', component: DashboardComponent, title: 'Dashboard'},
  {path: 'projects', component: ProjectsComponent, title: 'Projects'},

  {path: 'monitors', component: MonitorsComponent, title: 'Monitors'},
  {path: 'monitors/create', component: CreateMonitorComponent, title: 'Create-Monitor'},
  {path: 'monitors/edit', component: CreateMonitorComponent, title: 'Edit-Monitor'},
  // {path: 'groups',component:GroupComponent, title: 'Groups'},
  {path: 'users', component: UserMenuComponent, title: 'Users'},
  {path: 'status', component: StatusPageComponent, title: 'Status-Page'},
  {path: 'status/create', component: CreateStatusComponent, title: 'Create-Status-Page'},
  {path: 'status/edit-monitor', component: EditMonitorStatusComponent, title: 'Edit-Monitor-Status-Page'},
  {path: 'alerts', component: AlertListingComponent, title: 'Alert-Listing-Page'},
  {path: 'alerts/create', component: CreateAlertGroupComponent, title: 'Create-Alert-Group-Page'},
  {path: 'integrations', component: IntegrationsComponent, title: 'Integrations'},
  {path: 'users/create', component: CreateUserComponent, title: 'Create User'},
  {path: 'users/update/:userId', component: CreateUserComponent, title: 'Update User'},
  {path: 'slack/redirect', component: SlackOauthComponent},
  {path: 'monitors/monitors-detail', component: MonitorsDetailComponent, title:'Monitor-Detail'},
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {path: 'status-page/:statusPageId', component: EndUserStatusPageComponent, title: 'Monitors Status'},
  {path: 'incidents', component: IncidentsComponent, title: 'Incidents'},
  {path: 'actions', component: ActionsComponent, title: 'Actions'},
];
