<div [formGroup]="formGroup">
  <div formArrayName="formsArray">
    <div class="flex flex-row gap-3 mt-2 mb-2" *ngFor="let form of formsArray.controls; let i = index" [formGroupName]="i">
      <p-button icon="pi pi-plus" pTooltip="add a filter" [text]="true" (onClick)="addForm()"></p-button>
      <input pInputText type="text" formControlName="keyPath" placeholder="Enter JSON key path" />
      <p-select placeholder="operator" [options]="operators" formControlName="operator" optionLabel="name" optionValue="code">
      </p-select>
      <input pInputText type="text" formControlName="value" placeholder="Enter value" />
      @if(formsArray.length >1){
        <p-button pTooltip="remove this filter" icon="pi pi-times" [text]="true" (onClick)="removeForm(i)"></p-button>
      }
    </div>
  </div>
</div>

