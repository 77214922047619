<p-card class="">
  <div class="flex flex-column align-items-center justify-content-center ml-5 mr-5">
    <div class="flex flex-row gap-4 w-full align-items-center justify-content-between">
      <div class="flex flex-column">
        <h1>Carvia Tech</h1>
      </div>
      <div class="flex flex-column">
        <p-button class="ml-auto">Subscribe To Updates</p-button>
      </div>
    </div>
    <div class="flex flex-row align-items-center gap-3">
      <div>
        <h4>Monitor Stats</h4>
      </div>
      <carvia-coloured-stats-bar [statsBarInput]="items"></carvia-coloured-stats-bar>
    </div>
  </div>
</p-card>
