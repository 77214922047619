import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Route, Router, RouterOutlet} from '@angular/router';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {Button} from 'primeng/button';
import {NavbarComponent} from "./components/navbar/navbar.component";
import {MonitorsComponent} from "./pages/monitors/monitors.component";
import {KeycloakService} from 'keycloak-angular';
import {UsersService} from './services/users/users.service';
import {KeycloakProfile} from './models/users/users-response';
import {UserDataService} from './services/userData.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SidebarComponent, Button, NavbarComponent, MonitorsComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'watch-dog-web';
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  private readonly keycloakService: KeycloakService = inject(KeycloakService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private userService: UsersService = inject(UsersService);
  private userDataService: UserDataService = inject(UserDataService);

  private routesToHide = ['status-page', 'actions']
  displayNavAndSideBar: boolean = true;

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();

    if (this.isLoggedIn) {
      const token = await this.keycloakService.getToken();
      this.userDataService.getUserInfo(token);
      this.userDataService.user$.subscribe((user) => {
        if (user) {
          this.userProfile = user;
          this.userDataService.setUser(user);
        }
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkRoute();
      }
    });
    this.checkRoute();
  }

  private checkRoute() {
    const currentRoute = this.activatedRoute.snapshot.firstChild?.routeConfig?.path || '';
    const baseRoute = currentRoute.split('/')[0]; // Extract base route
    this.displayNavAndSideBar = !this.routesToHide.includes(baseRoute);
  }

  public login() {
    this.keycloakService.login();
  }

  public logout() {
    this.keycloakService.logout();
  }

}
