import { Pipe, PipeTransform } from '@angular/core';
import {Type} from '../../models/enums';
import { MonitorType } from '../../models/monitors/monitor-requests';

enum MonitorTypeConverterPipeArgs{
  TO_MONITOR_TYPE,
  TYPE
}

@Pipe({
  name: 'MonitorTypeConverter',
  standalone: true
})
export class MonitorTypeConverterPipe implements PipeTransform {

  transform(value: Type | MonitorType): Type | MonitorType {
    if((value as Type) !== undefined){
      return this.convertTypeToMonitorType(value as Type) ;
    } else if((value as MonitorType) !== undefined){
      return this.convertMonitorTypeToType(value as MonitorType);
    } else {
      throw new Error(`Invalid input provided in ClassName: MethodName, Please provide either a valid Type or MonitorType.`);
    }
  }

  private convertTypeToMonitorType(type: Type): MonitorType {
    let monitorType!: MonitorType;
    switch(type){
      case Type.HTTP_MONITOR:
        monitorType = {name: 'HTTPS', code: 'https'}
        break;
      case Type.SSL_MONITOR:
        monitorType = {name: 'SSL', code: 'ssl'}
        break;
    }
    return monitorType;
  }

  private convertMonitorTypeToType(monitorType: MonitorType): Type {
    let type!: Type;
    switch(monitorType.name){
      case 'SSL':
        type = Type.SSL_MONITOR
        break;
      case 'HTTPS':
        type = Type.HTTP_MONITOR
        break;
    }
    return type;
  }
}
