<div class="flex flex-row h-full m-2">

    <div class="flex flex-column col container">
        <div class="flex flex-row toolbar">
            <p-button label="Run Test" icon="pi pi-play" [loading]="runningTest" class="flex ml-auto" (onClick)="runAction()"></p-button>
        </div>
        <div class="">
            <ngx-codemirror (ngModelChange)="onChange($event)" [(ngModel)]="code"
                [options]="codeMirrorOptions"></ngx-codemirror>
        </div>
        
        <div class="p-5 bg-none">
            <h3>Output</h3>
            <pre class="json-pre">{{ result }}</pre>
        </div>
    </div>

    <div class="flex flex-column gap-2 col">
        <!-- <div class="flex flex-row toolbar">
        </div> -->
        <div class="pl-5 pr-5">
            <h1>Docs</h1>
            <p class="text-500-16px">
                Actions can be used to perform operations on multiple monitor execution results.
            </p>
            <p class="text-500-16px">
                Currently it only supports single method <code class="inline-code">execute</code>. Which can be called on <code class="inline-code">monitors</code> 
            </p>
            <h3>Example:</h3>
            <pre class="inline-code" >
const monitorResponse = monitors_17_execute;
monitorResponse
            </pre> 
            <p class="text-500-16px">
                Above code will return the response of monitor with id: 17
            </p>

            <code class="inline-code">Note: when you start typing monitors_ you will get suggestions for available monitors.</code> 
        </div>
    </div>
</div>
