import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {Card} from 'primeng/card';
import {Button} from 'primeng/button';
import {ColouredStatsBarComponent, ColorCodedStatsBars} from 'carvia';
@Component({
  selector: 'app-end-user-status-page',
  standalone: true,
  imports: [
    Card,
    Button,
    ColouredStatsBarComponent,
  ],
  templateUrl: './end-user-status-page.component.html',
  styleUrl: './end-user-status-page.component.scss'
})
export class EndUserStatusPageComponent implements OnInit{
  private route: ActivatedRoute = inject(ActivatedRoute)

  statusPageId!: string | null

  items: ColorCodedStatsBars = {
    bars: [
      { id: 1, opacity: 0.88, stat: 35, date: new Date(), toolTipData: {'UpTime': '35%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 2, opacity: 0.92, stat: 50, date: new Date(), toolTipData: {'UpTime': '50%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 3, opacity: 0.75, stat: 28, date: new Date(), toolTipData: {'UpTime': '28%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 4, opacity: 0.80, stat: 45, date: new Date(), toolTipData: {'UpTime': '45%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 5, opacity: 0.90, stat: 20, date: new Date(), toolTipData: {'UpTime': '20%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 6, opacity: 0.95, stat: 55, date: new Date(), toolTipData: {'UpTime': '55%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 7, opacity: 0.77, stat: 35, date: new Date(), toolTipData: {'UpTime': '35%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 8, opacity: 0.85, stat: 60, date: new Date(), toolTipData: {'UpTime': '60%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 9, opacity: 0.73, stat: 15, date: new Date(), toolTipData: {'UpTime': '15%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 10, opacity: 0.99, stat: 70, date: new Date(), toolTipData: {'UpTime': '70%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 11, opacity: 0.78, stat: 40, date: new Date(), toolTipData: {'UpTime': '40%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 12, opacity: 0.65, stat: 30, date: new Date(), toolTipData: {'UpTime': '30%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 13, opacity: 0.91, stat: 48, date: new Date(), toolTipData: {'UpTime': '48%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 14, opacity: 0.74, stat: 12, date: new Date(), toolTipData: {'UpTime': '12%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 15, opacity: 0.79, stat: 37, date: new Date(), toolTipData: {'UpTime': '37%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 16, opacity: 0.87, stat: 42, date: new Date(), toolTipData: {'UpTime': '42%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 17, opacity: 0.93, stat: 64, date: new Date(), toolTipData: {'UpTime': '64%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 18, opacity: 0.65, stat: 27, date: new Date(), toolTipData: {'UpTime': '27%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 19, opacity: 0.80, stat: 35, date: new Date(), toolTipData: {'UpTime': '35%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 20, opacity: 0.96, stat: 56, date: new Date(), toolTipData: {'UpTime': '56%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 21, opacity: 0.71, stat: 11, date: new Date(), toolTipData: {'UpTime': '11%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 22, opacity: 0.86, stat: 43, date: new Date(), toolTipData: {'UpTime': '43%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 23, opacity: 0.69, stat: 29, date: new Date(), toolTipData: {'UpTime': '29%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 24, opacity: 0.98, stat: 67, date: new Date(), toolTipData: {'UpTime': '67%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 25, opacity: 0.79, stat: 23, date: new Date(), toolTipData: {'UpTime': '23%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 26, opacity: 0.82, stat: 49, date: new Date(), toolTipData: {'UpTime': '49%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 27, opacity: 0.79, stat: 38, date: new Date(), toolTipData: {'UpTime': '38%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 28, opacity: 0.90, stat: 54, date: new Date(), toolTipData: {'UpTime': '54%', 'Date': new Date()}}, // Green (stat >= 40)
      { id: 29, opacity: 0.76, stat: 32, date: new Date(), toolTipData: {'UpTime': '32%', 'Date': new Date()}}, // Red (stat < 40)
      { id: 30, opacity: 0.94, stat: 58, date: new Date(), toolTipData: {'UpTime': '58%', 'Date': new Date()}}, // Green (stat >= 40)
    ],
    markRedAt: 40
  }

  ngOnInit() {
    this.statusPageId = this.route.snapshot.paramMap.get('statusPageId')
    console.log(this.statusPageId)
  }


}
