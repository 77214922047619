import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Menu } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { CommonModule } from '@angular/common';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {Toast} from 'primeng/toast';
import {MenuItem, MenuItemCommandEvent} from 'primeng/api';
import {KeycloakService} from 'keycloak-angular';
import {OverlayBadge} from 'primeng/overlaybadge';
import {Badge} from 'primeng/badge';
import {Slider, SliderSlideEndEvent} from 'primeng/slider';
import {InputText} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {Ripple} from 'primeng/ripple';
import {UserDataService} from '../../services/userData.service';
import {KeycloakProfile} from '../../models/users/users-response';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MenubarModule, ButtonModule, AvatarModule, CommonModule, Toast, Menu, OverlayBadge, Badge, Slider, InputText, FormsModule, Ripple],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit{
    @Output() sidebarVisibleChange = new EventEmitter<boolean>();
    @Input() sidebarVisible: boolean = false;
    private subscription!: Subscription;
    private sidebarState: SidebarStateService = inject(SidebarStateService)
    private keycloakService: KeycloakService = inject(KeycloakService);
    private userDataService: UserDataService = inject(UserDataService);

    items: MenuItem[] =  [
          // {
          //   label: 'Settings',
          //   icon: 'pi pi-cog',
          // },

          {

            template: 'userInfoName',  // Username section
          },
          {

            template: 'userInfoEmail',  // Email section
          },
          {
            label: 'Logout',
            icon: 'pi pi-upload',
            command: ()=>  {
              this.keycloakService.logout();
            }
          },{
            label: 'Slider',

          }
        ]
    username: string = '';
    email: string = '';
    value: number = 0;
    user: KeycloakProfile | null = null;

    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
      this.sidebarVisibleChange.emit(this.sidebarVisible);
      this.sidebarState.setSidebarVisible(this.sidebarVisible);
    }


  ngOnInit(): void {
    this.userDataService.getUser().subscribe((user: KeycloakProfile | null) => {
      this.user = user;
      this.username = user?.name || 'Guest';
      this.email = user?.email || 'No Email';
    });

    this.subscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {this.sidebarVisible = visible;}
    )
    this.getAppSize()
  }

  toggleDarkTheme() {
    const element = document.querySelector('html');
    element?.classList.toggle('watchdog-dark')
  }

  openMenu(menu: Menu, $event: MouseEvent) {
    menu.toggle($event)
  }

  setAppSize($event: SliderSlideEndEvent) {
    const el = document.documentElement;

    localStorage.setItem('size', this.value.toString())
    el.style.fontSize = `${$event.value}px`

  }
  getAppSize(){
    const rootEl = document.documentElement;
    const size = localStorage.getItem('size')
    if (size){
      this.value = Number.parseInt(size)
    } else {
      const computedFontSize = getComputedStyle(rootEl).fontSize;
      this.value = Number.parseInt(computedFontSize);
    }
  }

  getInitials(fullName: string | undefined): string {
    if (!fullName) return '';
    const names = fullName.split(' ');
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    }
    return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
  }

}
