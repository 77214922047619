<!--<div class="dashboard-screen lg:overflow-y-scroll" [ngClass]="{'reduced': sidebarVisible}">-->
<!--  <p>dashboard works!</p>-->
<!--  <div class="card">-->
<!--    <p-metergroup [value]="value" labelPosition="start">-->
<!--      <ng-template #label>-->
<!--        <div class="flex flex-wrap gap-4">-->
<!--          @for (meterItem of value; track meterItem.id) {-->
<!--            <ng-container>-->
<!--              <p-card class="flex-1" styleClass="border border-surface shadow-none">-->
<!--                <div class="flex justify-between gap-8">-->
<!--                  <div class="flex flex-col gap-1">-->
<!--                    <span class="text-surface-500 dark:text-surface-400 text-sm">{{ meterItem.label }}</span>-->
<!--                    <span class="font-bold text-lg">{{ meterItem.value }}%</span>-->
<!--                  </div>-->
<!--                  <span class="w-8 h-8 rounded-full inline-flex justify-center items-center text-center" [style]="{ 'background-color': meterItem.color1, color: '#ffffff' }">-->
<!--                                <i [class]="meterItem.icon"></i>-->
<!--                            </span>-->
<!--                </div>-->
<!--              </p-card>-->
<!--            </ng-container>-->
<!--          }-->

<!--        </div>-->
<!--      </ng-template>-->
<!--      <ng-template #meter let-value let-class="class" let-width="size">-->
<!--        <span [class]="class" [style]="{ background: 'linear-gradient(to right, ' + value.color1 + ', ' + value.color2 + ')', width: width }"></span>-->
<!--      </ng-template>-->
<!--      <ng-template #start let-totalPercent="totalPercent">-->
<!--        <div class="flex justify-between mt-4 mb-2 relative">-->
<!--          <span>Storage</span>-->
<!--          <span [style]="{ width: totalPercent + '%' }" class="absolute text-right">{{ totalPercent }}%</span>-->
<!--          <span class="font-medium">1TB</span>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </p-metergroup>-->
<!--  </div>-->


<!--  <p-table-->
<!--    [value]="data"-->
<!--    stripedRows-->
<!--    [paginator]="data.length >0"-->
<!--    [rows]="page.size"-->
<!--    [showCurrentPageReport]="data.length >0"-->
<!--    [tableStyle]="{ 'min-width': '50rem' }"-->
<!--    [lazy]="true"-->
<!--    (onPage)="onPageChange($event)"-->
<!--    [totalRecords]="page.totalElements"-->
<!--    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"-->
<!--    [rowsPerPageOptions]="[5,10,25,50]"-->
<!--    class="p-datatable-sm">-->
<!--    <ng-template pTemplate="header">-->
<!--      <tr>-->
<!--        <th>Status</th>-->
<!--        <th>Root Cause</th>-->
<!--        <th>Started</th>-->
<!--        <th>Duration</th>-->
<!--      </tr>-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="body" let-item>-->
<!--      <tr class="hover:bg-black-alpha-10">-->
<!--        <td>{{ item.status }}</td>-->
<!--        <td>{{ item.rootCause }}</td>-->
<!--        <td>{{ item.started }}</td>-->
<!--        <td>{{ item.duration }}</td>-->
<!--      </tr>-->
<!--    </ng-template>-->
<!--  </p-table>-->

<!--  <app-incidents></app-incidents>-->

<!--</div>-->


<div class="dashboard-screen flex flex-column gap-2 overflow-x-auto" [ngClass]="{'reduced': sidebarVisible}">
  <div class="heading text-gray-500">Dashboard</div>
  <div class="status-section flex-column lg:flex-row justify-content-center align-items-center gap-6">
    <div class="generic-card-dashboard col-11 lg:col-5">
      <span class="text-2xl w-full">Monitor Status</span>
      <div class="grid justify-content-center align-items-center">
        <div class="col flex flex-column align-items-center gap-2">
          <p-badge
            [value]="downCount"
            [style]="{ 'background-color': downCount === 0 ? 'white' : '#EF4444','color': downCount === 0 ? '#EF4444' : 'white', 'width': '80px', 'height': '80px', 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'border-radius': '50%', 'border': downCount === 0 ? '2px solid #EF4444' : '2px solid white', 'font-size': '5rem'}"
            class="font-bold  h-7rem w-7rem xl:h-9rem xl:w-9rem"
          ></p-badge>
          <span class="flex align-items-center text-lg font-bold text-red-500">
                Down
              </span>
        </div>
        <div class="col flex flex-column align-items-center gap-2">
          <p-badge
            [value]="upCount"
            [style]="{ 'background-color': upCount === 0 ?'white':'#22C55E', 'color': upCount === 0 ? '#22C55E': 'white', 'border': upCount === 0 ? '2px solid #22C55E' : '2px solid white', 'font-size': '5rem'}"
            class="font-bold  circle h-7rem w-7rem xl:h-9rem xl:w-9rem"
          ></p-badge>
          <span class="flex align-items-center text-lg font-bold text-green-500">
                Up
              </span>
        </div>
        <div class="col flex flex-column align-items-center gap-2 ">
          <p-badge
            [value]="pausedCount"
            [style]="{ 'background-color': pausedCount === 0 ?'white': '#64748B', 'color':pausedCount === 0 ? '#64748B': 'white', 'border': pausedCount === 0 ? '2px solid #64748B' : '2px solid white', 'font-size': '5rem'}"
            class="font-bold circle h-7rem w-7rem xl:h-9rem xl:w-9rem"
          ></p-badge>
          <span class="flex align-items-center text-lg font-bold text-gray-500">
                Paused
              </span>
        </div>
      </div>
    </div>

    @if(downCount==0){
      <div class="generic-card-dashboard col-11 lg:col-6">
        <div class="flex align-items-center justify-content-between">

            <div class="text-2xl font-medium ml-2"  [style]="{ 'color': '#475569', }"> Ongoing Incidences</div>

          <div class="custom-style" (click)="navigateToIncidents()">
            View all
          </div>
        </div>

        <div class="flex mt-3 justify-content-center align-items-center">
          <div class="no-active-incident">
            <div class="flex justify-content-center w-full">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_835_464)">
                  <mask id="mask0_835_464" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                    <path d="M48 0H0V48H48V0Z" fill="white"/>
                  </mask>
                  <g mask="url(#mask0_835_464)">
                    <path d="M37.9998 40H11.9998C5.74858 40.0036 0.541618 35.2076 0.0325574 28.9772C-0.476502 22.7466 3.88288 17.1691 10.0518 16.158C12.8913 11.1238 18.2201 8.00701 23.9998 7.99987C27.6038 7.98625 31.1048 9.20259 33.9238 11.448C36.692 13.6437 38.66 16.6899 39.5238 20.116C44.6916 20.91 48.3754 25.551 47.9758 30.7642C47.5764 35.9774 43.2282 40.0028 37.9998 40ZM23.9998 12C19.6633 12.0051 15.6652 14.3442 13.5358 18.122L12.5998 19.8L10.7018 20.11C6.6024 20.7968 3.71184 24.5086 4.05026 28.6514C4.38866 32.7942 7.84328 35.9876 11.9998 36H37.9998C41.137 36.0032 43.747 33.5892 43.9882 30.4614C44.2294 27.3334 42.0204 24.5478 38.9198 24.07L36.2878 23.67L35.6438 21.086C34.3144 15.7396 29.509 11.9899 23.9998 12ZM21.0558 33.42L14.0558 26.42L16.8758 23.6L21.0558 27.78L30.2558 18.58L33.0758 21.4L21.0578 33.418L21.0558 33.42Z" fill="#34C759"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_835_464">
                    <rect width="48" height="48" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="info-1 flex justify-content-center text-2xl my-2">
              No Active Incidient
            </div>
            <div class="info-2 flex justify-content-center text-md my-2">
              Everything seems to be working fine
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="generic-card-dashboard col-11 lg:col-6">
        <div class="flex align-items-center justify-content-between">
          <div class="flex align-items-center gap-2">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.4954 12.1402 12.1402 15.4954 8 15.5ZM7.25 10.25V11.75H8.75V10.25H7.25ZM7.25 4.25V8.75H8.75V4.25H7.25Z"
                fill="#EF4444"
              />
            </svg>
            <span class="text-2xl font-medium"  [style]="{ 'color': 'red', }"> Ongoing Incidences</span>
          </div>
          <div class="custom-style" (click)="navigateToIncidents()">
            View all
          </div>
        </div>

        <div class="grid-incident">
          @for (incident of projectIncidents.slice(0, 4); let i = $index; track projectIncidents) {
            <div
              class="ongoing-card p-3"
            >
              <div class="card-content flex justify-content-between">
                <span class="text-2xl">{{ incident.project.name }}</span>

                <div class="incident-info flex align-items-center">
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.01019 7.42424L9.47035 13.7961C9.55836 13.8988 9.66753 13.9812 9.79038 14.0377C9.91323 14.0942 10.0468 14.1234 10.1821 14.1234C10.3173 14.1234 10.4509 14.0942 10.5737 14.0377C10.6966 13.9812 10.8058 13.8988 10.8938 13.7961L16.3539 7.42424C16.875 6.81604 16.443 5.87659 15.6422 5.87659H4.72035C3.91957 5.87659 3.48753 6.81604 4.01019 7.42424Z" fill="#DC2626"/>
                  </svg>
                  <span class="incident-count font-bold" [style]="{ color: '#DC2626' }">
                    {{ incident.incidentCount }}
                </span>
                </div>
              </div>
              <div class="flex justify-content-between">
                <div class="flex flex-column">
                  @for(i of incident.incidents.slice(0,2); track i){
                    <div class="mb-1">{{i.monitor.name}}</div>
                  }
                </div>


                  @if(incident.incidents.length - 2 >0){
                    <div class="more mt-3 h-2rem align-content-center border-round-lg p-1">
                    {{incident.incidents.length - 2}} more
                    </div>

                  }

              </div>





            </div>
            @if(i==1){
              <div class="separator-line mx-auto mt-3"> </div>
              <div class="separator-line mx-auto mt-3"> </div>
            }
          }
        </div>
      </div>
    }
  </div>
  <div class="generic-card-dashboard-ongoing ml-7 mr-7 col-11 w-auto ">
    <div>
      <span class="text-2xl font-medium ml-2"  [style]="{ 'color': '#475569', }"> Ongoing Incidents</span>
    </div>
    @if (loading) {
      <app-skeleton-table [loading]="loading" [headers]="incidentHeader"></app-skeleton-table>
    }
    @if (incidentsData.length>0) {
      <p-table
        [value]="incidentsData"
        stripedRows
        [tableStyle]="{ 'min-width': '50rem' }"
        [lazy]="true"
        size="small">
        <ng-template pTemplate="header">
          <tr>
            @for (header of incidentHeader; track header) {
              <th>{{header}}</th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="text-500-16px">{{ item.project.name }}</td>
            <td class="text-500-16px">{{ item.monitor.name }}</td>
            <td class="text-500-16px" showDelay="1000" pTooltip="{{item.rootCause}}">{{ item.rootCause.substring(0,100) }}...</td>
            <td class="text-500-16px">{{ item.createdDate | date: 'dd-MM-yyyy HH:mm'  }}</td>
            <td class="text-500-16px">{{ item.incidentDuration | duration}}</td>
          </tr>
        </ng-template>
      </p-table>

    }
    @else if (incidentsData.length==0 && !loading){
      <div style="text-align: center;">
        <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_814_160)">
            <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_814_160">
              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg></div>
        <div class=" flex justify-content-center text-2xl my-2">
          No Data Found
        </div>
        <div class="info-2 flex justify-content-center text-xl my-2">
          There is no incidents. Everything seems to be working fine
        </div>

      </div>
    }
  </div>
</div>


