import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActionRequest } from '../models/requests';
import { environment } from '../../../environments/environment.dev';
import { ActionResponse } from '../models/responses';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  private httpClient: HttpClient = inject(HttpClient);

  runAction(actionRequest: ActionRequest) : Observable<ActionResponse>{
    return this.httpClient.post<ActionResponse>(`${environment.baseUri}/actions/run`, actionRequest)
  }
}
