<div [formGroup]="httpMonitorPayload" class="flex flex-column">
  <div  class="flex flex-column generic-card">
    <div class="flex flex-column mt-0">
      <span class="text-2xl mt-1 mb-1">URL*</span>
      <input class="mt-1 mb-1" pInputText type="text" aria-describedby="url-help" formControlName="httpUrl"/>
      <small id="url-help" class="mt-1">Enter the HTTP or HTTPS address to monitor</small>
      @if (httpMonitorPayload.controls['httpUrl'].invalid && httpMonitorPayload.controls['httpUrl'].touched) {
        <small class="error">Valid Url is required</small>
      }
    </div>
    <div class="flex flex-column mt-2">
      <span class="text-2xl mt-1 mb-1">Monitor Name*</span>
      <input pInputText class="mt-1" type="text" formControlName="name"/>
      @if (httpMonitorPayload.controls['name'].invalid && httpMonitorPayload.controls['name'].touched) {
        <small class="error">Name is required</small>
      }
    </div>
    <div class="flex flex-column mt-2">
      <span class="text-2xl mt-1 mb-1">Scheduler</span>
      <p-selectbutton [options]="schedulerOptions" optionLabel="name" optionValue="code" formControlName="disableScheduler"/>
    </div>
  </div>
  <div class="generic-card">
    <div class="flex-column flex">
      <span class="text-2xl">Check Frequency*</span>
      <div class="flex inline-flex gap-4 mt-3">
        <p-selectbutton optionValue="code" optionLabel="name" formControlName="scheduleInterval" [options]="intervals" />
      </div>
      @if (httpMonitorPayload.controls['scheduleInterval'].invalid && httpMonitorPayload.controls['scheduleInterval'].touched) {
          <small class="error">Frequency is required</small>
        }
    </div>
    <div class="flex flex-column mt-5">
      <span class="text-2xl">HTTP method*</span>
      <div class="flex inline-flex gap-4 mt-3">
        <p-selectbutton formControlName="httpMethod" [options]="httpMethods" />
      </div>
      @if (httpMonitorPayload.controls['httpMethod'].invalid && httpMonitorPayload.controls['httpMethod'].touched) {
          <small class="error">Method is required</small>
        }
    </div>
    <div class="flex flex-column mt-5">
      <span class="text-2xl">Expected Response</span>
      <div class="flex inline-flex gap-4 mt-3">
        <p-multiSelect placeholder="Response Code" optionLabel="name" optionValue="code" formControlName="expectedResponse" class="w-4" [options]="values" display="chip"/>

      </div>
      @if (httpMonitorPayload.controls['expectedResponse'].invalid && httpMonitorPayload.controls['expectedResponse'].touched) {
        <small class="error">Code is required</small>
      }
    </div>
  </div>

  <div class="generic-card">
    <div class="flex-column flex">
      <span class="text-2xl mb-3">Response Validation</span>
      <div>
        <app-response-validation-input [resultValidationArgList]="resultValidationArgs" class="mb-5"></app-response-validation-input>
      </div>
    </div>
  </div>
</div>
