import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Dialog} from 'primeng/dialog';
import {Button} from 'primeng/button';
import {InputText} from 'primeng/inputtext';
import {EmailValidator, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {EmailIntegrationPayload} from '../../models/integrations/integration-requests';
import {MessageService} from 'primeng/api';
import {IntegrationService} from '../../services/integrations/integration.service';
import {ProgressSpinner} from 'primeng/progressspinner';
import {IntegrationResponse} from '../../models/integrations/integration-response';

@Component({
  selector: 'app-create-email-integration',
  standalone: true,
  imports: [
    Dialog,
    Button,
    InputText,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinner
  ],
  templateUrl: './create-email-integration.component.html',
  styleUrl: './create-email-integration.component.scss'
})
export class CreateEmailIntegrationComponent implements OnInit{
  @Input() visible: boolean = false;
  loading: boolean = false;
  emailAddress: FormControl = new FormControl('', [Validators.required, Validators.email])
  @Output() newEmailIntegrationEvent = new EventEmitter<IntegrationResponse>()
  @Output() creationCancelledEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  private messageService: MessageService = inject(MessageService);
  private integrationService: IntegrationService = inject(IntegrationService)


  ngOnInit() {

  }

  createEmailIntegration() {
    this.loading = true;
    if(this.emailAddress.hasError('required') || this.emailAddress.hasError('email')){
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please provide a valid email address!'})
      this.loading = false;
      return;
    }
    const payload : EmailIntegrationPayload= {
      emailId: this.emailAddress.value
    }
    this.integrationService.createEmailIntegration(payload).subscribe({
      next: res => {
        this.newEmailIntegrationEvent.emit(res);
      },
      error: err => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error creating Integration!'});
        this.visible = false;
      },
      complete: () => {
        setTimeout(()=>{
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Created Integration Successfully!'});
          this.loading = false;
          this.visible = false;
        }, 1000)
      }
    })
  }

  dialogClose() {
    console.log('closed')
    this.creationCancelledEvent.emit(true);
  }

}
