import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.dev';
import {AuditLog, Incident, Monitor} from '../../models/monitors/monitor-responses';
import {BehaviorSubject, Observable} from 'rxjs';
import {PaginatedResponse} from '../../models/commons';
import {FilteredIncidents} from '../../incident/incident-request';
import {Alerts} from '../../models/alerts';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {
  private selectedProjectSubject = new BehaviorSubject<any>(null);
  selectedProject$ = this.selectedProjectSubject.asObservable();
  constructor(private httpClient: HttpClient) { }

  setSelectedProject(project: any) {
    this.selectedProjectSubject.next(project);
  }

  getSelectedProject() {
    return this.selectedProjectSubject.value;
  }

  public delete(id: number) {
    console.log('deleting monitor')
    return this.httpClient.delete<Monitor>(environment.baseUri+'/monitor/' + id );
  }

  public getAll(type:string,projectId: string){
    console.log('getting monitors', environment.baseUri)
    return this.httpClient.get<Monitor[]>(environment.baseUri+'/monitor',{
      params: new HttpParams()
        .set('projectId', projectId)
        .set('type', type)
    })
  }

  public restart(id: number) {
    console.log('restart monitor')
    return this.httpClient.patch(environment.baseUri+'/monitor/'+id+'/restart', {});
  }

  public pause(id: number) {
    console.log('pause monitor')
    return this.httpClient.patch(environment.baseUri+'/monitor/'+id+'/pause', {});
  }

  public run(id: number) {
    console.log('pause monitor')
    return this.httpClient.post(environment.baseUri+'/monitor/'+id+'/execute', {});
  }

  getIncidentDetails(page: number, size: number, monitorId: string): Observable<PaginatedResponse<Incident>> {
    return this.httpClient.get<PaginatedResponse<Incident>>(
      environment.baseUri + `/incident/monitor/${monitorId}`, {
        params: new HttpParams()
          .set('page', page.toString())
          .set('size', size.toString())
      }
    );
  }
  getAlerts( page: number, size: number,monitorId: string): Observable<PaginatedResponse<Alerts>> {
    return this.httpClient.get<PaginatedResponse<Alerts>>(
      environment.baseUri + `/monitor/${monitorId}/alerts`, {
        params: new HttpParams()
          .set('page', page.toString())
          .set('size', size.toString())
      }
    );
  }
  getAuditLogsOfMonitor(page: number, size: number, monitorId: string): Observable<PaginatedResponse<AuditLog>> {
    return this.httpClient.get<PaginatedResponse<AuditLog>>(
      environment.baseUri + `/audit-log/monitor/${monitorId}`, {
        params: new HttpParams()
          .set('page', page.toString())
          .set('size', size.toString())
      }
    );
  }
  getMonitorsDetailById(monitorId:string){
    return this.httpClient.get(environment.baseUri +`/monitor/${monitorId}`)
  }
}
