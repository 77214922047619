import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef, EventEmitter,
  Input, OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {DragDropModule} from 'primeng/dragdrop';
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-drag-zone',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    DragDropModule,
    CdkDrag,
    CdkDropList
  ],
  templateUrl: './drag-zone.component.html',
  styleUrl: './drag-zone.component.scss'
})
export class DragZoneComponent implements OnInit {
  @Input() items: any[] = [];
  @Output() itemsChange: EventEmitter<string[]> = new EventEmitter();
  @Input() displayKey!: string;


  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    console.log(this.items)
  }

  ngOnInit(): void {
    console.log('items', this.items);
    this.items.forEach(item => {
      if(item[this.displayKey] === undefined || item[this.displayKey] === null){
        console.error('provided display key is not present in all items.')
      }
    })
  }
}
