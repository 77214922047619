@if (loading){
  <div class="loader-container">
    <p-progress-spinner ariaLabel="loading" />
  </div>
}
<form [formGroup]="sslPayloadForm" class="flex flex-column" >
  <p-toast/>
  <div class="flex flex-column generic-card">
    <div class="flex flex-column mt-0">
      <span class="text-2xl mt-1 mb-1">URL*</span>
      <input class="mt-1 mb-1" pInputText type="text" aria-describedby="url-help" formControlName="httpUrl"/>
      <small id="url-help" class="mt-1">Enter URL of the website to monitor its SSL certificate.</small>
      @if (sslPayloadForm.controls['httpUrl'].invalid && sslPayloadForm.controls['httpUrl'].touched) {
        <small class="error">Valid Url is required</small>
      }
    </div>
    <div class="flex flex-column mt-2">
      <span class="text-2xl mt-1 mb-1">Monitor Name*</span>
      <input  class="mt-1" type="text" formControlName="name" pInputText/>
      @if (sslPayloadForm.controls['name'].invalid && sslPayloadForm.controls['name'].touched) {
        <small class="error">Name is required</small>
      }
    </div>
  </div>
  <div class="generic-card">
    <div class="flex-column flex">
      <span class="text-2xl">Certificate Expiry Threshold</span>
      <div class="flex inline-flex gap-4 mt-3">
        <p-selectButton [options]="certificateExpiryThreshold" formControlName="expiryThreshold"></p-selectButton>
      </div>
      @if (sslPayloadForm.controls['expiryThreshold'].invalid && sslPayloadForm.controls['expiryThreshold'].touched) {
        <small class="error">Threshold is required</small>
      }
    </div>
  </div>
</form>
