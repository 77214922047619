import {Component, inject} from '@angular/core';
import {Button, ButtonDirective} from "primeng/button";
import {ConfirmDialog} from "primeng/confirmdialog";
import {Menubar} from "primeng/menubar";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {Toast} from "primeng/toast";
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {NgClass} from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Select} from 'primeng/select';
import {InputText} from 'primeng/inputtext';
import {StatusService} from '../../services/status.service';
import {StatusRequest} from '../../models/status-request';

@Component({
  selector: 'app-create-status',
  standalone: true,
  imports: [
    Button,
    ConfirmDialog,
    Menubar,
    PrimeTemplate,
    Toast,
    NgClass,
    Select,
    InputText,
    ReactiveFormsModule,
    ButtonDirective
  ],
  templateUrl: './create-status.component.html',
  styleUrl: './create-status.component.scss',
  providers: [ConfirmationService, MessageService]

})
export class CreateStatusComponent {
  sideBarVisible: boolean = false;
  private subscription!: Subscription;
  statusForm: FormGroup;
  private route: ActivatedRoute = inject(ActivatedRoute);

  statusId: string | any;
  statusName: string | any;
  statusUrl: string | any;
  statusAccess: string | any;
  private messageService: MessageService = inject(MessageService);

  constructor(private sidebarState: SidebarStateService, private router: Router, private fb: FormBuilder, private statusService: StatusService,
              private confirmationService: ConfirmationService) {
    this.statusForm = this.fb.group({
      name: [null, Validators.required],
      homePageUrl: [null]
    });
  }

  ngOnInit(): void {
    this.subscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sideBarVisible = visible;
      }
    );

    this.route.queryParams.subscribe(params => {
      this.statusId = params['statusId'];

      if (this.statusId) {
        this.getStatusById();
      }
    });
  }

  getStatusById() {
    this.statusService.getStatusById(this.statusId).subscribe(
      (response: StatusRequest) => {
        this.statusForm.patchValue({
          name: response.name,
          homePageUrl: response.homePageUrl
        });
        this.statusAccess=response.access;
      },
      (error) => {
        console.error('Error fetching status:', error);
      }
    );
  }

  navigateAway() {
    this.confirmationService.confirm({
      message: '<div class="flex flex-column justify-content-center align-items-center"><span>Are you sure?</span><span>Your changes to the form will be lost!</span></div>',
      icon: 'pi pi-info-circle',
      header: 'Confirmation',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.backToStatusPage();
      },
      reject: () => {
      },
      key: 'positionDialog'
    })
  }

  backToStatusPage() {
    this.router.navigate(['/status']);
  }

  goToEditStatusPage(statusId: string, name: string, homePageUrl: string, access: string) {
    this.router.navigate(['/status/edit-monitor'], {
      queryParams: {statusId, name, homePageUrl, access}
    });
  }


  onSubmit() {
    if (this.statusForm.valid) {
      const payload = this.statusForm.value;
      const updatedPayload = {
        ...payload,
        access: this.statusAccess,
      };
      if (this.statusId) {
        this.statusService.updateStatus(this.statusId, updatedPayload).subscribe(
          (response) => {
            console.log('Status updated successfully:', response);
          },
          (error) => {
            console.error('Error updating status:', error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update status. Please try again'});
          },
          () => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Status Updated Successfully!'});
            setTimeout(() => {
              this.backToStatusPage();
            },1000)

          }
        );
      } else {
        this.statusService.createStatus(payload).subscribe(
          (response) => {
            console.log('Status created successfully:', response);

            const statusId = response.id;
            const name = response.name;
            const homePageUrl = response.homePageUrl;
            const access = response.access;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Status Updated Successfully!'});
            this.goToEditStatusPage(statusId, name, homePageUrl, access);
          },
          (error) => {
            console.error('Error creating status:', error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to create status. Please try again'});

          }
        );
      }
    } else {
      alert('Form is invalid. Please check the fields and try again.');
    }
  }
}
