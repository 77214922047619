<div class="add-user lg:overflow-y-scroll" [ngClass]="{'reduced': sidebarVisible}">
  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <p-toast/>
      <p-confirmDialog
        key="positionDialog"
        rejectButtonStyleClass="p-button-outlined"/>
      <div (click)="navigateToUsers()" class="go-back">
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.57578 2.55965C6.84033 2.2951 6.84033 1.86619 6.57578 1.60164C6.31123 1.33709 5.88232 1.33709 5.61777 1.60164L0.198412 7.02099C-0.0661372 7.28554 -0.0661372 7.71446 0.198412 7.97901L5.61777 13.3984C5.88232 13.6629 6.31123 13.6629 6.57578 13.3984C6.84033 13.1338 6.84033 12.7049 6.57578 12.4403L2.31285 8.17742H13.3226C13.6967 8.17742 14 7.87413 14 7.5C14 7.12587 13.6967 6.82258 13.3226 6.82258H2.31285L6.57578 2.55965Z"
                fill="#334155"/>
        </svg>
        <span class="text-500-14px">Go back</span>
      </div>
    </ng-template>
  </p-menubar>
  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <span class="text-500-24px">  {{ isEditUser ? 'Update User' : 'Create User' }}</span>
    </ng-template>
  </p-menubar>

  <p-toast/>
  <p-confirmDialog/>
  <p-card styleClass="user-form" class=" justify-content-center ">
    <form class="form custom-card" [formGroup]="userForm" (ngSubmit)="saveUser()" novalidate>
      <div class="row">
        <div class="field">
          <label for="name" class="block">Name</label>
          <input id="name" class="text-500-14px" type="text" pInputText placeholder="Name" formControlName="name"/>
          <small class="p-error block" *ngIf="
          userForm.get('name')?.invalid && userForm.get('firstName')?.dirty
        ">
            First name is required</small>
        </div>

        <div class="field">
          <label for="mobile" class="block ">Mobile</label>
          <input id="mobile" class="text-500-14px" type="text" pInputText placeholder="Mobile"
                 formControlName="mobile"/>
          <small class="p-error block" *ngIf="
          userForm.get('mobile')?.invalid && userForm.get('mobile')?.dirty
        ">
            Mobile is required</small>
        </div>
      </div>

      <div class="row">
        <div class="field">
          <label for="username" class="block">User Name</label>
          <input id="username" class="text-500-14px " type="text" pInputText placeholder="User Name"
                 formControlName="username"/>
          <small class="p-error block" *ngIf="
          userForm.get('username')?.invalid && userForm.get('username')?.dirty
        ">
            User Name is required</small>
        </div>

        <div class="field">
          <label for="email" class="block">Email</label>
          <input id="email" class="text-500-14px" type="text" pInputText placeholder="Email" formControlName="email"/>
          <small class="p-error block" *ngIf="
          userForm.get('email')?.invalid && userForm.get('email')?.dirty
        ">
            Email is required</small>
        </div>
      </div>

      <div *ngIf="!isEditUser" class="row">
        <div class="field">
          <label for="password" class="block">Password</label>
<!--          <input id="password" class="text-500-14px" type="text" pInputText placeholder="Password"-->
<!--                 formControlName="password"/>-->
          <p-password
            id="password"
            [formControl]="passwordControl"
            toggleMask="true"
            [placeholder]="'Password'"
          ></p-password>
          <small class="p-error block" *ngIf="
          userForm.get('password')?.invalid && userForm.get('password')?.dirty
        ">
            Password is required!</small>
        </div>

        <div class="field">
          <label for="confirmPassword" class="block">Confirm Password</label>
<!--          <input id="confirmPassword"  class="text-500-14px" type="text" pInputText placeholder="Confirm Password"-->
<!--                 formControlName="confirmPassword"/>-->
          <p-password
            id="confirmPassword"
            [formControl]="confirmPasswordControl"
            toggleMask="true"
            [placeholder]="'Confirm Password'"
          ></p-password>
          <small class="p-error block" *ngIf="
          userForm.get('confirmPassword')?.invalid && userForm.get('confirmPassword')?.dirty
        ">
            Confirm Password is required!</small>
          <small class="p-error block" *ngIf="userForm.hasError('passwordMismatch') && userForm.get('confirmPassword')?.dirty">
            Passwords must match!</small>
        </div>
      </div>


      <div class="submit-button-container">
        <button pButton type="submit" label="Submit"
                [disabled]="userForm.invalid"></button>
      </div>

    </form>
  </p-card>
</div>
