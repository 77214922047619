import {Component, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {TableModule} from "primeng/table";
import {DatePipe, NgClass} from '@angular/common';
import {Subscription} from 'rxjs';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {IncidentsComponent} from '../incidents/incidents.component';
import {ProjectService} from '../../services/projectService';
import {ProjectIncidents, ProjectRequest} from '../../models/project/project-request';
import {Badge} from 'primeng/badge';
import {DashboardService} from '../../services/dashboardService';
import {Incident, MonitorStatusCount} from '../../models/monitors/monitor-responses';
import {Router} from '@angular/router';
import {DurationPipe} from '../../pipes/duration-pipe/duration.pipe';
import {Tag} from 'primeng/tag';
import {Tooltip} from 'primeng/tooltip';
import {SkeletonTableComponent} from '../../components/common/skeleton-table/skeleton-table.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    FormsModule,
    TableModule,
    NgClass,
    Badge,
    DatePipe,
    DurationPipe,
    Tag,
    Tooltip,
    SkeletonTableComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  projects: ProjectRequest[] = []
  monitorStatusData: MonitorStatusCount | null = null;
  projectIncidents: ProjectIncidents[]=[];
  sidebarVisible !: boolean;
  sidebarSubscription !: Subscription;
  upCount: number = 0;
  downCount: number = 0;
  pausedCount: number = 0;
  incidentsData:Incident[]=[]
  loading = true;
  incidentHeader: string[] = []

  @ViewChild('incidentsComponent') incidentsComponent!: IncidentsComponent;
  value = [
    { id:1,label: 'Apps', color1: '#34d399', color2: '#fbbf24', value: 25, icon: 'pi pi-table' },
    { id:2,label: 'Messages', color1: '#fbbf24', color2: '#60a5fa', value: 15, icon: 'pi pi-inbox' },
    { id:3,label: 'Media', color1: '#60a5fa', color2: '#c084fc', value: 20, icon: 'pi pi-image' },
    { id:4,label: 'System', color1: '#c084fc', color2: '#c084fc', value: 10, icon: 'pi pi-cog' }
  ];
  constructor(private sidebarState: SidebarStateService, private projectService:ProjectService, private dashboardService:DashboardService, private router: Router) {
  }
  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.incidentHeader = ['Project Name', 'Monitor Name', 'Root Cause','Started','Duration (HH:MM:SS)'];

    this.monitorStatusCount();
    this.getOngoingProjectIncident();
    this.getOngoingIncidents();
  }

  monitorStatusCount(){
    this.dashboardService.getMonitorStatusCount().subscribe({
      next: data => {
        this.monitorStatusData = data;
        this.upCount = this.monitorStatusData.upMonitorCount;
        this.downCount = this.monitorStatusData.downMonitorCount;
        this.pausedCount = this.monitorStatusData.pausedMonitorCount;
      },
      error: error => {
        console.log(error);
      },
      complete: () => {
      }
    })
  }

  getOngoingProjectIncident(){
    this.dashboardService.getOngoingProjectIncidents().subscribe({
      next: data => {
        this.projectIncidents = data;

      },
      error: error => {
        console.log(error);
      },
      complete: () => {
      }
    })
  }

  getOngoingIncidents(){
    this.dashboardService.getOngoingIncidents().subscribe({
      next: (response) => {
        const { content, page } = response;
        this.incidentsData = content;
        // this.page = page;
        this.loading = false;

      },
      error: error => {
        console.log(error);
      },
      complete: () => {
        this.loading = false;

      }
    })
  }

  navigateToIncidents(){
    this.router.navigate(['/incidents']);
  }

}
