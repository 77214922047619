

export enum Operator{
    GT = 'GT',
    LT = 'LT',
    EQ = 'EQ',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS'
}

export const OperatorMap: {[key: string]: string} = {
    'GT': '>',
    'LT': '<',
    'EQ':'==',
    'CONTAINS':'contains',
    'NOT_CONTAINS':'does not contain'
}

export interface ResponseValidationRequest {
    keyPath: string
    operator: string
    value: string
}
