
export enum Type{
  HTTP_MONITOR = 'HTTP_MONITOR',
  SSL_MONITOR = 'SSL_MONITOR'
}

export enum MonitorStatus {
  UP="UP",
  DOWN="DOWN"
}

export enum ExpiryThreshold {
  '30 days'= 'THIRTY_DAYS',
  '20 days'= 'TWENTY_DAYS',
  '10 days'= 'TEN_DAYS',
  '7 days'= 'SEVEN_DAYS',
  '2 days'= 'TWO_DAYS'
}

export enum ExpectedHttpResponses {
  '2xx'=200,
}

export enum ScheduleIntervals {
  '1 minute' = 'ONE_MINUTE',
  '5 minutes' = 'FIVE_MINUTES',
  '30 minutes' = 'THIRTY_MINUTES',
  '1 hour' = 'ONE_HOUR',
  '5 hours' = 'FIVE_HOURS',
  '12 hours' = 'TWELVE_HOURS',
  '24 hours' = 'TWENTY_FOUR_HOURS'
}

export enum HttpMethods {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
  OPTIONS = "OPTIONS",
}

export enum MonitorEventType {
  UPDATE,
  PATCH,
  DELETE,
  CREATE,
  PAUSE,
  RESTART
}
