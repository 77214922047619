

export interface EmailIntegrationPayload {
  emailId: string
}

export interface IntegrationTypes {
  name: string
  code: string
}

export enum AuthType {
  SLACK = 'SLACK'
}

export interface SlackIntegrationPayload{
  code: string
  redirectUri: string
  authType: AuthType
}

