import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertGroupResponse} from '../../models/alert-group/alert-group-response';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.dev';
import {AlertGroupPayload} from '../../models/alert-group/alert-group-payload';
import {IntegrationResponse} from '../../models/integrations/integration-response';

@Injectable({
  providedIn: 'root'
})
export class AlertGroupService {

  private httpClient: HttpClient = inject(HttpClient);
  constructor() { }

  getAll(): Observable<AlertGroupResponse[]>{
    return this.httpClient.get<AlertGroupResponse[]>(environment.baseUri+'/alert-group');
  }

  create(payload: AlertGroupPayload): Observable<AlertGroupResponse> {
    return this.httpClient.post<AlertGroupResponse>(`${environment.baseUri}/alert-group`, payload)
  }

  getAlertGroupById(alertId:number): Observable<any> {
    return this.httpClient.get(environment.baseUri + `/alert-group/${alertId}`);
  }

  updateAlertGroup(alertId: number, payload:any){
    return this.httpClient.put<AlertGroupResponse>(`${environment.baseUri}/alert-group/${alertId}`, payload);
  }

}
