<div class="alert-listing-pane" [ngClass]="{'reduced': sidebarVisible}">
  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <span class="text-500-20px">Alert Groups</span>
    </ng-template>
    <ng-template pTemplate="end">
      <p-button label="Create Alert Group" icon="pi pi-plus" (click)="navigateToCreateAlert()"></p-button>
    </ng-template>
  </p-menubar>
  <p-menubar class="menu">
    <ng-template pTemplate="start" >
      <div class="flex align-items-center gap-3">
<!--        TODO: enable once working-->
        <input [disabled]="true" type="text" class="border-round-xl w-16rem" placeholder="Search" pInputText [(ngModel)]="searchInputText" />
        <span class="text-xl border-left-1 pl-4 ml-4 p-1">Quick Filter</span>
<!--        TODO: enable once working-->
        <div class="card flex justify-content-center ml-4">
          <p-select
            [disabled]="true"
            optionLabel="name"
            [showClear]="true"
            placeholder="Type" />
        </div>
      </div>
    </ng-template>

  </p-menubar>
  <div class="m-2">
    <div class="overflow-x-auto w-full cards-container">
      @if (loading) {
        <app-skeleton-table [loading]="loading" [headers]="alertsHeader"></app-skeleton-table>
      }
      @if (alertGroups.length>0){
    <p-table
      [value]="alertGroups"
      [loading]="loading"
      stripedRows
      size="small"
    >
      <ng-template #header>
        <tr>
          @for (header of alertsHeader; track header) {
            <th>{{header}}</th>
          }
        </tr>
      </ng-template>
      <ng-template #body let-alertGroup>
        <tr class="text-500-16px">
          <td>{{ alertGroup.name }}</td>
          <td>{{ alertGroup.description ?? '--'}}</td>
          <td>{{ alertGroup.createdAt | date: 'dd-MMM-yyyy HH:mm:ss' }}</td>
          <td>
            <div class="card flex justify-center relative">
              <i class="pi pi-ellipsis-h cursor-pointer align-self-center relative" (click)="showMenu($event, menu, alertGroup.id)"></i>
              <p-menu #menu [model]="items" [popup]="true" appendTo="body" class="custom-menu"></p-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
      }@else if (alertGroups.length==0 && !loading){
        <div style="text-align: center; margin-top: 4%">
          <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_814_160)">
              <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_814_160">
                <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg></div>
          <div class=" flex justify-content-center text-2xl my-2">
            No Data Found
          </div>
          <div class="info-2 flex justify-content-center text-xl my-2">
            There is no alert groups yet.
          </div>

        </div>
      }
  </div>
  </div>

</div>
