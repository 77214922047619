
import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import {CodemirrorModule, CodemirrorComponent} from '@ctrl/ngx-codemirror'
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import 'codemirror/addon/hint/show-hint';
import {CompletionContext} from '@codemirror/autocomplete'
import { hint, showHint } from 'codemirror';
import { MonitorService } from '../../services/monitor/monitor.service';
import { HttpMonitorResponse, Monitor } from '../../models/monitors/monitor-responses';
import showHints from '../editor-utils/utils';
import { debounceTime, Observable, Subject, switchMap, timeout } from 'rxjs';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { ActionService } from '../service/action.service';
import { ActionRequest } from '../models/requests';
import { ActionResponse } from '../models/responses';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-actions',
  standalone: true,
  imports: [CodemirrorModule
    ,CommonModule
    , FormsModule
    , MenubarModule
    , ToastModule
    ,ConfirmDialog
    ,ButtonModule
  ],
  templateUrl: './actions.component.html',
  styleUrl: './actions.component.scss'
})
export class ActionsComponent implements AfterViewInit, OnInit{

  @ViewChild(CodemirrorComponent) codeMirrorComponent!: CodemirrorComponent;
  private actionService: ActionService = inject(ActionService);
  private messageService: MessageService = inject(MessageService);
  private changeSubject: Subject<any> = new Subject();
  code: string = `//write code here`;

  private monitorService: MonitorService = inject(MonitorService)
  monitorType: string = 'HTTP_MONITOR'
  monitors!: Monitor[];
  results!: any[];
  result!: String;
  runningTest: boolean = false;

  ngAfterViewInit(): void {

  }
  ngOnInit(){
    this.monitorService.getAll(this.monitorType, '').subscribe({
      next: (res) => {
        this.monitors = res;
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {}
    });
    this.changeSubject.pipe(
      debounceTime(500),
      switchMap((event: any) => {
        const editor = this.codeMirrorComponent.codeMirror;
        return new Observable((observer) => {
          showHints(editor, this.monitors);
          observer.next();
        });
      })
    ).subscribe();
  }

  runAction() {
    this.runningTest = true;
    this.result = ''

    const req: ActionRequest = {
      code: this.code,
      projectId: 1
    }
    this.actionService.runAction(req).subscribe({
      next: (res: ActionResponse) => {
        if(!res.error) {
          this.result = this.logTheObj(res.runResult);

        } else if(res.error && res.error.length !== 0){
          this.result = res.error.join("\n");
        } 
      },
      error: (err) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error running action!'});
        this.runningTest = false;
      },
      complete: () => {
        this.runningTest = false
      }
    })

  }

  logTheObj(obj:any) {
    let parsed = JSON.parse(obj)
    return JSON.stringify(parsed, null, 2);
  }

  getLatestResult(monitorId: string){
    let pageIndex: number = 0;
    let pageSize: number = 1;
    this.monitorService.getAuditLogsOfMonitor(pageIndex, pageSize, monitorId).subscribe({
      next: (response: any) => {
        const {content, page} = response;
        this.results = content;
      },
      error: (err) => {
        console.error('Error fetching incident details', err);
      }
    });
  }

  autocompleteFunction = (context: CompletionContext) => {
    let word = context.matchBefore(/\w*/)
    if (word){
      if (word.from == word.to && !context.explicit)
        return null
      return {
        from: word.from,
        options: [
          {label: "match", type: "keyword"},
          {label: "hello", type: "variable", info: "(World)"},
          {label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro"}
        ]
      }
    }
    return null;
  };
  codeMirrorOptions: any = {
    mode: "javascript",
    indentWithTabs: true,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: false,
    extraKeys: { "Ctrl-Space": "autocomplete" },
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    hint: true,
  };


  onChange($event: any) {
    this.changeSubject.next($event);
  }

}
