import {Component, inject} from '@angular/core';
import {CommonModule, DatePipe, NgClass, NgForOf} from '@angular/common';
import {Subscription} from 'rxjs';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {ConfirmationService, MessageService, PrimeTemplate} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {Menubar} from 'primeng/menubar';
import {TableModule, TableRowCollapseEvent, TableRowExpandEvent} from 'primeng/table';
import {MonitorService} from '../../services/monitor/monitor.service';
import {Page} from '../../models/commons';
import {FormsModule} from '@angular/forms';
import {TabPanel, TabView, TabViewChangeEvent} from 'primeng/tabview';
import {AuditLog, ExecutionValidationResult} from '../../models/monitors/monitor-responses';
import moment from 'moment';
import {BarAdditionalInfo, ColorCodedStatsBars, ColouredStatsBarComponent} from 'carvia';
import {SkeletonTableComponent} from '../../components/common/skeleton-table/skeleton-table.component';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OperatorMap } from '../../models/response-validation/requests';
import { DurationPipe } from "../../pipes/duration-pipe/duration.pipe";
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-monitors-detail',
  standalone: true,
  imports: [
    NgClass,
    PrimeTemplate,
    Menubar,
    TableModule,
    TabPanel,
    FormsModule,
    TabView,
    TabPanel,
    DatePipe,
    ColouredStatsBarComponent,
    SkeletonTableComponent,
    NgForOf,
    ButtonModule,
    CommonModule,
    CardModule,
    DurationPipe,
    TooltipModule,
    TagModule,
  ],
  templateUrl: './monitors-detail.component.html',
  styleUrl: './monitors-detail.component.scss'
})
export class MonitorsDetailComponent {
  sidebarVisible !: boolean;
  sidebarSubscription !: Subscription;
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);
  monitorId: number | any;
  httpUrl: string | any;
  incidents24Hours = '1 incident, 1d down';
  monitorData: any = {};
  lastCheck = 'Coming soon';
  activeTabIndex: number = 0;
  tabNames: string[] = ['Incidents', 'Logs','Alerts'];
  logsData: AuditLog[] = [];
  incidenceHeaders: string[] = []
  logsHeader: string[] = []
  graphInput: ColorCodedStatsBars = {
    markRedAt: 99,
    bars: [] as { id: number; opacity: number; stat: number; date: Date; toolTipData: BarAdditionalInfo }[]
  }
  logsPage: Page = {size: 10, totalElements: 0, totalPages: 0, number: 0};
  incidencePage: Page = {size: 10, totalElements: 0, totalPages: 0, number: 0};
  alertsPage: Page = {size: 10, totalElements: 0, totalPages: 0, number: 0};
  incidenceData = [];
  alertsData = [];
  loading = false;
  shimmerLoading: boolean = true
  expandedRowKeys: { [s: string]: boolean; } = {};
  alertsHeader: string[] = []
  constructor(private sidebarState: SidebarStateService, private monitorService: MonitorService, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      (visible) => {
        this.sidebarVisible = visible;
      }
    );

    this.route.queryParams.subscribe((params) => {
      this.monitorId = params['monitorId'];
      this.httpUrl = params['httpUrl'];

      const tabName = params['tab'];
      if (tabName) {
        this.activeTabIndex = this.tabNames.indexOf(tabName);
        if (this.activeTabIndex === -1) {
          this.activeTabIndex = 0;
        }
        if (tabName === 'Logs') {
          this.getAuditLogs({page: 0, size: this.logsPage.size});
          this.logsHeader = ['Executed On', 'Execution Time', 'Status'];
        } else if (tabName === 'Incidents') {
          this.getIncidentDetails({page: 0, size: this.incidencePage.size});
          this.incidenceHeaders = ['Status', 'Root Cause', 'Started', 'Duration'];
        }else if (tabName === 'Alerts') {
          this.getAlerts({page: 0, size: this.alertsPage.size});
          this.alertsHeader = ['Created Date', 'Status'];
        }
      } else {
        this.getIncidentDetails({page: 0, size: this.incidencePage.size});
        this.incidenceHeaders = ['Status', 'Root Cause', 'Started', 'Duration'];
      }
    });
    this.getMonitorsDetailById();
  }

  backToMonitorPage() {
    this.router.navigate(['/monitors']);
  }
  onIncidencePageChange(event: any): void {
    console.log("changing page: ", event.first, ",", event.rows)
    this.shimmerLoading = true;
    this.incidenceData = [];
    this.getIncidentDetails(event);
  }
  onAlertsPageChange(event: any): void {
    console.log("changing page: ", event.first, ",", event.rows)
    this.shimmerLoading = true;
    this.alertsData = [];
    this.getAlerts(event);
  }

  onLogsPageChange(event: any): void {
    console.log(event);
    this.shimmerLoading = true;
    this.logsData = []
    this.getAuditLogs(event);
  }

  onTabChange(event: any): void {
    const tabName = this.tabNames[event.index];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {tab: tabName},
      queryParamsHandling: 'merge',
    });
    const activeTabIndex = event.index;
    if (activeTabIndex === 0) {
      this.incidenceData = [];
    } else if (activeTabIndex === 1) {
      this.logsData = [];
    } else if (activeTabIndex === 2) {
      this.alertsData = [];
    }
  }

  getIncidentDetails(event: any) {
    this.loading = true;
    this.shimmerLoading = true;
    const pageIndex = event.first ? event.first / event.rows : 0;
    const pageSize = event.rows || this.incidencePage.size;
    this.monitorService.getIncidentDetails(pageIndex, pageSize, this.monitorId).subscribe({
      next: (response: any) => {
        const {content, page} = response;
        this.incidenceData = content;
        this.incidencePage = page;
        console.log(`Total Records: ${page.totalRecords}`);
        this.loading = false;
        this.shimmerLoading = false;
      },
      error: (err) => {
        console.error('Error fetching incident details', err);
        this.loading = false;
        this.shimmerLoading = false;
      },
    });
  }

  getAuditLogs(event: any) {
    this.loading = true;
    this.shimmerLoading = true;
    const pageIndex = event.first ? event.first / event.rows : 0;
    const pageSize = event.rows || this.logsPage.size;
    this.monitorService.getAuditLogsOfMonitor(pageIndex, pageSize, this.monitorId).subscribe({
      next: (response: any) => {
        const {content, page} = response;
        this.logsData = content;
        this.logsPage = page;
        this.loading = false;
        this.shimmerLoading = false;
      },
      error: (err) => {
        console.error('Error fetching incident details', err);
        this.loading = false;
        this.shimmerLoading = false;
      }
    });
  }

  getAlerts(event: any) {
    this.loading = true;
    this.shimmerLoading = true;
    const pageIndex = event.first ? event.first / event.rows : 0;
    const pageSize = event.rows || this.alertsPage.size;

    this.monitorService.getAlerts(pageIndex, pageSize, this.monitorId).subscribe({
      next: (response: any) => {
        console.log(response,'response')
        const {content, page} = response;
        this.alertsData = content;
        this.alertsPage = page;
        this.loading = false;
        this.shimmerLoading = false;
      },
      error: (err) => {
        console.error('Error fetching incident details', err);
        this.loading = false;
        this.shimmerLoading = false;
      },
    });
  }

  getMonitorsDetailById(): void {
    this.monitorService.getMonitorsDetailById(this.monitorId).subscribe(
      (response: any) => {
        console.log(response, 'response');
        this.monitorData = response;
        const hourlyStats = response.monitor24HourStatsDto.hourlyStats;
        if (Array.isArray(hourlyStats)) {
          this.graphInput.bars = hourlyStats.map((stat: any, index: number) => {
            let toolTipData: { [key: string]: string | number | Date } = {
              'Time': this.formatTime(stat.hourStart),
            };
            if (stat.uptimePercentage !== null) {
              toolTipData['UpTime'] = stat.uptimePercentage;
            } else {
              toolTipData['Insufficient Data'] = 'Data Not Available';
            }
            return {
              id: index + 1,
              opacity: this.calculateOpacity(stat.uptimePercentage),
              stat: stat.uptimePercentage,
              date: new Date(stat.hourStart),
              toolTipData: toolTipData
            };
          });
        } else {
          console.error('hourlyStats is not an array');
        }
      },
      (error) => {
        console.error('Error fetching monitor data:', error);
      }
    );
  }

  formatTime(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleTimeString();
  }

  calculateOpacity(stat: number): number {
    return Math.max(0.5, Math.min(1, stat / 100));
  }

  onRowExpand($event: TableRowExpandEvent) {
  }
  onRowCollapse($event: TableRowCollapseEvent) {
  }
  parseValidation(validationResult: ExecutionValidationResult){
      return validationResult.responseJsonPath + ' ' + OperatorMap[validationResult.operator] + ' ' + validationResult.value;
  }
  logTheObj(obj:any) {
    return JSON.stringify(JSON.parse(obj), null, 2);
  }

}
