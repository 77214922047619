import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PaginatedResponse} from '../models/commons';
import {Incident, MonitorStatusCount} from '../models/monitors/monitor-responses';
import {environment} from '../../environments/environment.dev';
import {FilteredIncidents} from '../incident/incident-request';
import {ProjectIncidents, ProjectRequest} from '../models/project/project-request';
import {MonitorStatus} from '../models/enums';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  data: any[] = [];

  constructor(private httpClient: HttpClient) {
  }

  getProjectFilteredIncidents(page: number, size: number, projectId: string,status:string): Observable<PaginatedResponse<FilteredIncidents>> {
    return this.httpClient.get<PaginatedResponse<FilteredIncidents>>(
      environment.baseUri + `/dashboard/incidents/filtered`, {
        params: new HttpParams()
          .set('page', page.toString())
          .set('size', size.toString())
          .set('projectId', projectId)
          .set('status', status)
      }
    );
  }


  getMonitorStatusCount(): Observable<MonitorStatusCount> {
    return this.httpClient.get<MonitorStatusCount>(`${environment.baseUri}/dashboard/monitors/count`);
  }

  getOngoingProjectIncidents(): Observable<ProjectIncidents[]> {
    return this.httpClient.get<ProjectIncidents[]>(`${environment.baseUri}/dashboard/projects/incidents`);
  }

  getOngoingIncidents(): Observable<PaginatedResponse<Incident>> {
    return this.httpClient.get<PaginatedResponse<Incident>>(`${environment.baseUri}/incident/ongoing`);
  }
}
