<div class="user-listing lg:overflow-y-scroll" [ngClass]="{'reduced': sidebarVisible}">
  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <span class="text-500-20px">Users</span>
    </ng-template>
    <ng-template pTemplate="end">
      <p-button label="New User" icon="pi pi-plus" (onClick)="navigateToAddUser()"></p-button>
    </ng-template>
  </p-menubar>
  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <div class="flex align-items-center gap-3">
        <input type="search"
               class="border-round-xl w-16rem"
               placeholder="Search"
               [(ngModel)]="searchQuery"
               (input)="onSearchChange()"
               pInputText/>
      </div>
    </ng-template>
  </p-menubar>
  <p-toast />
  <p-confirmDialog/>
  <div class="m-2">
    @if (loading) {
      <app-skeleton-table [loading]="loading" [headers]="userHeader"></app-skeleton-table>
    }
    @if (users.length>0) {
    <p-table [value]="users" [paginator]="true" [rows]="page.size" [showCurrentPageReport]="true"
           [lazy]="true"
           [scrollable]="true"
           scrollHeight="450px"
           [loading]="isLoading"
           (onPage)="onPageChange($event)"
           [totalRecords]="page.totalElements"
           [responsiveLayout]="'scroll'"
           [tableStyle]="{'min-width': '50rem'}"
           size="small"
           stripedRows="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [rowsPerPageOptions]="[5,10,25,50]">
    <ng-template pTemplate="header">
      <tr>
        @for (header of userHeader; track header) {
          <th>{{header}}</th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user let-i="rowIndex" class="relative">
      <tr class="text-500-16px" (contextmenu)="onRowContextMenu($event)">
        <td>{{ user.id }}</td>
        <td>{{ user.name }}</td>
        <td>{{ user.username }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.mobile }}</td>
        <td>{{ user.role }}</td>
        <td> <p-toggleswitch
          [(ngModel)]="user.enabled"
          (onChange)="toggleUserState(user)">
        </p-toggleswitch> </td>
        <td>
          <div class="card flex justify-center relative">
            <i class="pi pi-ellipsis-h cursor-pointer align-self-center relative" (click)="showMenu($event, menu, user)"></i>
            <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body" class="custom-menu"></p-menu>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
    } @else if (users.length==0 && !loading){
      <div style="text-align: center; margin-top: 4%">
        <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_814_160)">
            <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_814_160">
              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg></div>
        <div class=" flex justify-content-center text-2xl my-2">
          No Data Found
        </div>
        <div class="info-2 flex justify-content-center text-xl my-2">
          There is no users.
        </div>

      </div>
    }
  </div>
</div>
