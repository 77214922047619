import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EmailIntegrationPayload, SlackIntegrationPayload} from '../../models/integrations/integration-requests';
import {IntegrationResponse, PaginatedIntegrationResponse} from '../../models/integrations/integration-response';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  private httpClient: HttpClient = inject(HttpClient);

  createEmailIntegration(payload: EmailIntegrationPayload): Observable<IntegrationResponse> {
    return this.httpClient.post<IntegrationResponse>(`${environment.baseUri}/email`, payload);
  }

  getAll(): Observable<PaginatedIntegrationResponse>{
    return this.httpClient.get<PaginatedIntegrationResponse>(`${environment.baseUri}/integrations`)
  }

  createSlackIntegration(payload: SlackIntegrationPayload): Observable<any> {
    console.log('sending code to BE');
    return this.httpClient.post<any>(`${environment.baseUri}/app/oauth`, {},{params: {
        code: payload.code,
        redirectUri: payload.redirectUri,
        authType: payload.authType,
      }});
  }

  deleteIntegration(integrationId: number): Observable<any> {
    return this.httpClient.delete(`${environment.baseUri}/integration/${integrationId}/delete`);
  }

  updateAlertIntegration(alertId: number, payload:any){
    return this.httpClient.put<IntegrationResponse>(`${environment.baseUri}/alert-group/${alertId}`, payload);
  }
}
