<div class="create-status-container flex flex-column gap-2 overflow-x-auto" [ngClass]="{'reduced': sideBarVisible}" >
  <div class="flex flex-column">
    <p-menubar class="menu" >
      <ng-template pTemplate="start">
        <p-toast />
        <p-confirmDialog
          key="positionDialog"
          rejectButtonStyleClass="p-button-outlined" />
        <div (click)="navigateAway()" class="go-back">
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57578 2.55965C6.84033 2.2951 6.84033 1.86619 6.57578 1.60164C6.31123 1.33709 5.88232 1.33709 5.61777 1.60164L0.198412 7.02099C-0.0661372 7.28554 -0.0661372 7.71446 0.198412 7.97901L5.61777 13.3984C5.88232 13.6629 6.31123 13.6629 6.57578 13.3984C6.84033 13.1338 6.84033 12.7049 6.57578 12.4403L2.31285 8.17742H13.3226C13.6967 8.17742 14 7.87413 14 7.5C14 7.12587 13.6967 6.82258 13.3226 6.82258H2.31285L6.57578 2.55965Z" fill="#334155"/>
          </svg>
          <span class="text-500-14px">go back</span>
        </div>
      </ng-template>
    </p-menubar>
    <p-menubar class="menu">
      <ng-template pTemplate="start">
        <span class="text-500-24px">{{ statusId ? 'Update Status' : 'Create Status' }}</span>
      </ng-template>
      <ng-template pTemplate="end">
        <p-button
          label="Save Status"
          class="text-black-alpha-90"
          (onClick)="onSubmit()">
        </p-button>
      </ng-template>
    </p-menubar>

    <form [formGroup]="statusForm" (ngSubmit)="onSubmit()" class="generic-card">
      <div class="flex flex-column">
        <label for="name" class="text-2xl ">Status Name*</label>
        <input id="name" pInputText formControlName="name" class="full-width" placeholder="Enter Name" />
      </div>

      <div class="flex flex-column">
        <label for="homePageUrl" class="text-2xl">Home Page Url</label>
        <input id="homePageUrl" pInputText formControlName="homePageUrl" class="full-width" placeholder="Enter Home Page Url" />
      </div>
    </form>
  </div>
</div>
