<div class="drag-zone">
<!--  <div class="drag-zone">-->
  @if (items.length>0){
    <div
      cdkDropList
      class="example-list"
      cdkDropListOrientation="vertical"
      (cdkDropListDropped)="drop($event)">
      <div class="flex flex-column overflow-x-auto item-container">
        @for (item of items; track item){
          <div cdkDrag>{{item[displayKey]}}</div>
        }
      </div>
    </div>
  } @else {
    <div class="flex justify-content-center align-items-center h-full">
      <div class="text-center "><span class="text-2xl not-found">Please select items</span></div>
    </div>
  }
<!--  </div>-->
</div>
