<p-toast></p-toast>
@if(loading) {
  <p-progress-spinner></p-progress-spinner>
}
<div class="projects" [ngClass]="{'reduced': sidebarVisible}">
  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <span class="text-500-20px">Projects</span>
    </ng-template>
    <ng-template pTemplate="end">
      <p-button label="Create Project" icon="pi pi-plus" (onClick)="navigateToCreateProject()"></p-button>
    </ng-template>
  </p-menubar>

  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <div class="flex align-items-center gap-3">
        <input
          [disabled]="true"
          type="text"
          class="border-round-xl w-16rem"
          placeholder="Search"
          pInputText
          [(ngModel)]="searchInputText"
        />
      </div>
    </ng-template>
  </p-menubar>

  <div class="m-2">
    <div class="overflow-x-auto w-full cards-container">
      @if (loading) {
        <app-skeleton-table [loading]="loading" [headers]="projectsHeader"></app-skeleton-table>
      }
      @if (projects.length>0) {
      <p-table [value]="projects" [loading]="loading" [rows]="10" size="small" stripedRows>
        <ng-template pTemplate="header">
          <tr>
            @for (header of projectsHeader; track header) {
              <th>{{header}}</th>
            }
          </tr>
        </ng-template>
        <ng-template #body let-project>
          <tr class="text-500-16px">
            <td>{{ project.id }}</td>
            <td>{{ project.name }}</td>
            <td>{{ project.description }}</td>
            <td>{{ project.createdDate | date: 'dd-MMM-yyyy HH:mm:ss' }}</td>
            <td>{{ project.updatedDate | date: 'dd-MMM-yyyy HH:mm:ss' }}</td>
            <td>
              <div style="position: relative;">
                <button
                  pButton
                  icon="pi pi-ellipsis-v"
                  class="p-button-text"
                  (click)="showMenu($event,menu,project)">
                </button>
                <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body" class="custom-menu"></p-menu>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      } @else if (projects.length==0 && !loading){
        <div style="text-align: center; margin-top: 4%">
          <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_814_160)">
              <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_814_160">
                <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg></div>
          <div class=" flex justify-content-center text-2xl my-2">
            No Data Found
          </div>
          <div class="info-2 flex justify-content-center text-xl my-2">
            There is no projects yet. Please add some projects
          </div>
        </div>
      }
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="showModal"
  [header]="isEditProject ? 'Update Project' : 'Create Project'"
  [modal]="true"
  [closable]="true"
  [style]="{width: '50vw'}"
  (onHide)="closeModal()"
>
  <form [formGroup]="projectForm" (ngSubmit)="saveProject()" novalidate>
    <div class="form-row ">
      <div class="form-field m-2">
        <label for="name">Project Name</label>
        <input
          id="name"
          type="text"
          pInputText
          placeholder="Enter project name"
          formControlName="name"
          class="form-input w-full"
        />
          @if (projectForm.get('description')?.invalid && projectForm.get('description')?.dirty) {
              <small class="error-message">
                  Project description is required
              </small>
          }
      </div>
    </div>

    <div class="form-row">
      <div class="form-field m-2 mt-2">
        <label for="description">Project Description</label>
        <textarea
          id="description"
          pTextarea
          placeholder="Enter project description"
          formControlName="description"
          class="form-input w-full"
        ></textarea>
          @if (projectForm.get('description')?.invalid && projectForm.get('description')?.dirty) {
              <small class="error-message">
                  Project description is required
              </small>
          }
      </div>
    </div>

    <!-- Submit & Cancel Buttons -->
    <div class="form-buttons">
      <button pButton type="button" label="Cancel" class="p-button-text" (click)="closeModal()"></button>
      <button pButton type="submit" label="Submit" [disabled]="projectForm.invalid"></button>
    </div>
  </form>
</p-dialog>
