import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
  standalone: true
})
export class DurationPipe implements PipeTransform {

  transform(value: number | null | undefined): string {
    if (typeof value !== 'number' || value < 0) {
      return '00s';
    }

    const totalMilliseconds = value;
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const milliseconds = totalMilliseconds % 1000;

    if (hours > 0) {
      return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(seconds)}s`;
    } else if (minutes > 0) {
      return `${this.pad(minutes)}m ${this.pad(seconds)}s`;
    } else if (seconds > 0) {
      return `${this.pad(seconds)}s`;
    } else {
      return `${this.pad(milliseconds)} ms`;
    }

  }

  private pad(num: number): string {
    return num.toString().padStart(2, '0');
  }

}
