import {Component, OnInit} from '@angular/core';
import {Button, ButtonDirective} from "primeng/button";
import {InputText} from "primeng/inputtext";
import {Menu} from "primeng/menu";
import {Menubar} from "primeng/menubar";
import {MenuItem, PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {Subscription} from 'rxjs';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {NgClass} from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Dialog} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {MonitorService} from '../../services/monitor/monitor.service';
import {Router} from '@angular/router';
import {Card} from 'primeng/card';
import {StatusService} from '../../services/status.service';
import {StatusRequest} from '../../models/status-request';
import {TieredMenu} from 'primeng/tieredmenu';
import { Monitor } from '../../models/monitors/monitor-responses';
import {Select} from "primeng/select";
import {SkeletonTableComponent} from '../../components/common/skeleton-table/skeleton-table.component';

@Component({
  selector: 'app-status-page',
  standalone: true,
  imports: [
    Button,
    ButtonDirective,
    InputText,
    Menu,
    Menubar,
    PrimeTemplate,
    TableModule,
    NgClass,
    Dialog,
    ReactiveFormsModule,
    DropdownModule,
    Card,
    TieredMenu,
    Select,
    FormsModule,
    SkeletonTableComponent,
  ],
  templateUrl: './status-page.component.html',
  styleUrl: './status-page.component.scss'
})
export class StatusPageComponent implements OnInit{
  loading: boolean = false;
  sidebarVisible !: boolean;
  private sidebarSubscription !: Subscription;
  showModal: boolean = false;
  addMonitorForm: FormGroup;
  monitors: Monitor[] = [];
  tableData: StatusRequest[] = [];
  statusHeader: string[] = []

  items: MenuItem[] = []
  constructor(private fb: FormBuilder,
              private statusService:StatusService,
              private monitorService:MonitorService,
              private sidebarState: SidebarStateService,
              private router: Router,) {
    this.addMonitorForm = this.fb.group({
      monitorUrl: [null, Validators.required]
    });

  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.statusHeader = ['Status Name', 'Home Page Url', 'Access Level','Action'];

    this.getStatus();

  }


  goToEditStatusPage(statusId: string, name: string,homePageUrl:string,access:string){
    this.router.navigate(['/status/edit-monitor'], {
      queryParams: { statusId, name, homePageUrl, access }
    });
  }
  goToCreateStatusPage(statusId: string){
    this.router.navigate(['/status/create'], {
      queryParams: { statusId }
    });
  }

  getStatus() {
    this.loading = true;
    this.statusService.getAllStatus().subscribe(
      (response: StatusRequest[]) => {
        if (response.length > 0) {
          this.tableData = response;
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  navigateToCreateStatus() {
    this.router.navigate(['/status/create']);
  }

  card = {
    handle: {
      background: '#EEF2FF'
    }
  }

  showMenu($event: MouseEvent, menu: Menu, data: any) {

    if(!data) {
      console.error('Invalid status page')
    }

    this.items =  [
      {
        label: 'Edit Monitor',
        icon: 'pi pi-pencil',
        command: () => {
          console.log('Edit Monitor clicked for:', data);
          this.goToEditStatusPage(data.id, data.name, data.homePageUrl, data.access);
        },
      },
      {
        label: 'Global Settings',
        icon: 'pi pi-cog',
        command: () => {
          this.goToCreateStatusPage(data.id);
          console.log('Global Settings clicked');
        },
      },
    ];

    menu.toggle($event)
  }
}
