import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {ChipModule} from 'primeng/chip';
import {DatePipe, NgClass, NgIf} from '@angular/common';
import {Image} from 'primeng/image';
import {MonitorEventType, MonitorStatus} from '../../models/enums';
import {Menu, MenuItemContent, MenuModule} from 'primeng/menu';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {OverlayModule} from 'primeng/overlay';
import {Popover} from 'primeng/popover';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {MonitorService} from '../../services/monitor/monitor.service';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {Toast} from 'primeng/toast';
import {ProgressSpinner} from 'primeng/progressspinner';
import {MonitorEvent} from '../../models/events';
import { Router } from '@angular/router';
import { state } from '@angular/animations';
import { Monitor } from '../../models/monitors/monitor-responses';

export enum ViewMode {
  LIST = 'list',
  TABLE = 'table'
}

@Component({
  selector: 'app-monitor-card',
  standalone: true,
  imports: [CardModule, ButtonModule, ChipModule, NgIf, NgClass, Image, Menu, MenuModule, MenuItemContent, OverlayModule, Popover, OverlayPanelModule, ConfirmDialog, Toast, ProgressSpinner, DatePipe],
  templateUrl: './monitor-card.component.html',
  styleUrl: './monitor-card.component.scss',
  providers: [ConfirmationService]
})
export class MonitorCardComponent{

  @Input() monitor!: Monitor;
  @Input() sidebarVisible!:boolean;
  menuItems!: MenuItem[];
  selectedMonitorId!:number;
  loading: boolean = false;

  private monitorService: MonitorService = inject(MonitorService)
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  private messageService: MessageService = inject(MessageService);
  private router: Router = inject(Router);
  @Output() updatedMonitorEvent = new EventEmitter<MonitorEvent>();
  protected readonly MonitorStatus = MonitorStatus;
  @Input() view: string = 'list';


  showMenu(event: Event, menu: any, monitor: Monitor ) {
    this.selectedMonitorId = monitor.id;

    this.menuItems = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.editMonitor(monitor)
      },
      {
        label: 'Run Now',
        icon: 'pi pi-play',
        command: () => this.runMonitor(monitor),
      },
      !this.monitor.paused ? {
        label: 'Pause',
        icon: 'pi pi-pause',
        command: () => this.pause(monitor),
      } : {

        label: 'Restart',
        icon: 'pi pi-play',
        command: () => this.restart(monitor),
      },
      { separator: true },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.deleteConfirmation(monitor),
      },
    ];
    console.log("mouse event", event);
    menu.toggle(event);
  }

  editMonitor(monitor: Monitor) {
    console.log('edit monitor', monitor.id);
    this.router.navigate(["monitors/edit"], {state: {monitor}})
  }

  pause(monitor: Monitor) {
    this.loading = true;
    this.monitorService.pause(monitor.id).subscribe({
      next: () => {
        console.log('pause', monitor.id);
      },
      error: () => {
        this.loading = false;
        console.error('error', monitor.id);
      },
      complete: () => {
        this.loading = false;
        this.monitor.paused = true;
        this.updatedMonitorEvent.emit({type: MonitorEventType.PAUSE, monitor: monitor});
      }
    })
  }
  runMonitor(monitor: Monitor) {
    this.loading = true;
    this.monitorService.run(monitor.id).subscribe({
      next: () => {
        console.log('run', monitor.id);
      },
      error: () => {
        this.loading = false;
        console.error('error', monitor.id);
        this.messageService.add({
          key: 'monitorToast',
          severity: 'error',
          summary: 'Error',
          detail: 'Error executing monitor!',
        });
      },
      complete: () => {
        this.loading = false;
        this.messageService.add({
          key: 'monitorToast',
          severity: 'success',
          summary: 'Success',
          detail: 'Monitor executed successfully!',
        });
        this.updatedMonitorEvent.emit({type: MonitorEventType.PAUSE, monitor: monitor});
      }
    })
  }

  restart (monitor: Monitor) {
    this.loading = true;
    this.monitorService.restart(monitor.id).subscribe({
      next: () => {
        console.log('restart', monitor.id);
      },
      error: () => {
        this.loading = false;
        console.error('error', monitor.id);
      },
      complete: () => {
        this.loading = false;
        this.monitor.paused = false;
        this.updatedMonitorEvent.emit({type: MonitorEventType.PAUSE, monitor: monitor});
      }
    })
  }

  deleteConfirmation(monitor: Monitor) {
    const monitorId = monitor.id;
    console.log('deleteConfirmation', monitorId);
    this.confirmationService.confirm({
      message: '<div class="flex flex-column justify-content-center align-items-center"><span>Are you sure?</span><span>Record will be deleted</span></div>',
      icon: 'pi pi-info-circle',
      header: 'Confirmation',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.delete(monitorId);
      },
      reject: () => {},
      key: 'positionDialog'
    })
  }


  delete(monitorId: number) {
    this.loading = true;
    console.log('deleteMonitor', monitorId);
    this.monitorService.delete(monitorId).subscribe({
      next: (res) => {
        console.log('deleted monitor', res);
        this.updatedMonitorEvent.emit({type: MonitorEventType.DELETE, monitor: res});
      },
      error: (error) => {
        this.loading = false;
        this.messageService.add({
          key: 'monitorToast',
          severity: 'error',
          summary: 'Error',
          detail: 'Error deleting monitor!',
        });
        console.error('error', error);
      },
      complete: () => {
        this.loading = false;
        this.messageService.add({
          key: 'monitorToast',
          severity: 'success',
          summary: 'Success',
          detail: 'Monitor deleted successfully!',
        });
        console.log('complete');
      }
    })
  }

  goToMonitorDetailsPage(monitorId:number, httpUrl:string){
    this.router.navigate(["monitors/monitors-detail"], {
      queryParams: { monitorId, httpUrl }
    });
  }
}
