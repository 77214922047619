import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class SlackOauthService {

  authSlack(){
    const clientId = environment.slack.clientId;
    const redirectUri = environment.slack.redirectUri;
    const scope = environment.slack.scope;
    console.log('Redirecting');
    window.location.href = `${environment.slack.authEndpoint}?redirect_uri=${redirectUri}&scope=${scope}&client_id=${clientId}`;
  }
}
