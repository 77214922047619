import {HttpClient, HttpParams} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.dev';
import { Observable } from 'rxjs';
import {HttpMonitorResponse, SslMonitorResponse} from '../../../models/monitors/monitor-responses';
import {HttpMonitorPayload, SslMonitorPayload} from '../../../models/monitors/monitor-requests';

@Injectable({
  providedIn: 'root'
})
export class SslMonitorService {
  private httpClient: HttpClient = inject(HttpClient);

  create(payload: SslMonitorPayload,projectId: string): Observable<HttpMonitorResponse>{
    const url = `${environment.baseUri}/ssl-monitor?projectId=${projectId}`;
    return this.httpClient.post<HttpMonitorResponse>(url, payload);
  }

  get(id: number): Observable<SslMonitorResponse>{
    return this.httpClient.get<SslMonitorResponse>(environment.baseUri+'/ssl-monitor/'+id);
  }

  update(id: number, payload: SslMonitorPayload, projectId: string): Observable<SslMonitorResponse> {
    const params = new HttpParams().set('projectId', projectId);

    return this.httpClient.put<SslMonitorResponse>(
      `${environment.baseUri}/ssl-monitor/${id}`,
      payload,
      { params }
    );
  }
}
