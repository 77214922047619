import {Component, Input, OnInit} from '@angular/core';
import {TableModule} from 'primeng/table';
import {NgForOf, NgIf} from '@angular/common';
import {Skeleton} from 'primeng/skeleton';

@Component({
    selector: 'app-skeleton-table',
    standalone: true,
    imports: [
        TableModule,
        NgForOf,
        NgIf,
        Skeleton
    ],
    templateUrl: './skeleton-table.component.html',
    styleUrl: './skeleton-table.component.scss'
})
export class SkeletonTableComponent implements OnInit {
    @Input() loading: boolean = false;
    @Input() headers: string[] = [];
    @Input() rows: number = 5
    skeletonRows: any[] = [];

    constructor() {
    }

    ngOnInit(): void {
        if (this.loading) {
            this.skeletonRows = Array.from({length: this.rows}).map((_, i) => `Item #${i}`);
        }
    }

}
