import {Component, inject, OnInit} from '@angular/core';
import {SlackOauthService} from '../../login/integrations/slack/slack-oauth/service/slack-oauth.service';

@Component({
  selector: 'app-create-slack-integration',
  standalone: true,
  imports: [],
  template: `Redirecting`
})
export class CreateSlackIntegrationComponent implements OnInit{
  private slackService: SlackOauthService = inject(SlackOauthService);
  createSlackIntegration(){
    this.slackService.authSlack();
  }

  ngOnInit(): void {
    this.createSlackIntegration()
  }
}
