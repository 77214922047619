import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment.dev';
import {Observable} from 'rxjs';
import {StatusRequest} from '../models/status-request';
import {Monitor, StatusPageMonitorResponse} from '../models/monitors/monitor-responses';



@Injectable({
  providedIn: "root"
})

export class StatusService {
  data: any[] = [];

  constructor(private httpClient: HttpClient) {
  }

  createStatus(payload: any): Observable<any> {
    return this.httpClient.post(environment.baseUri + '/status-page', payload);
  }
  public getAllStatus(){
    return this.httpClient.get<StatusRequest[]>(environment.baseUri+'/status-page')
  }
  public getAllMonitors(){
    console.log('getting monitors', environment.baseUri)
    return this.httpClient.get<Monitor[]>(environment.baseUri+'/monitor')
  }
  addMonitors(statusId:string,payload: any): Observable<any> {
    return this.httpClient.put(environment.baseUri + `/status-page/${statusId}/monitors`, payload);
  }
  getStatusById(statusId:string): Observable<any> {
    return this.httpClient.get(environment.baseUri + `/status-page/${statusId}`);
  }
  getMonitorsByStatusId(statusId:string): Observable<StatusPageMonitorResponse[]> {
    return this.httpClient.get<StatusPageMonitorResponse[]>(environment.baseUri + `/status-page/${statusId}/monitors`);
  }
  updateStatus(statusId:string,payload: any): Observable<any> {
    return this.httpClient.put(environment.baseUri + `/status-page/${statusId}`, payload);
  }

}
