import { Monitor } from "../../models/monitors/monitor-responses";

const showHints = (editor: any, monitors: Monitor[]) => {
    if (editor) {
        const cursor = editor.getCursor();

        let wordRange = editor.findWordAt(cursor);
        let token = editor.getTokenAt(cursor);

        let prevCursor = { line: cursor.line, ch: cursor.ch - 1 };
        let prevToken = editor.getTokenAt(prevCursor);

        let currentWord: string = editor.getRange(wordRange.anchor, wordRange.head);
        let combinedWord: string = prevToken.string + token.string;

        if (currentWord === 'monitors') {
            editor.replaceRange('monitors', wordRange.anchor, wordRange.head);
        }

        if (currentWord === 'monitors' || combinedWord === 'monitors_') {
            let monitorHints = monitors.map(monitor => ({
                text: `monitors_${monitor.id}`,
                displayText: monitor.name,
                docs: `Monitor ID: ${monitor.id}`
            }));

            editor.showHint({
                hint: () => ({
                    from: { line: cursor.line, ch: cursor.ch - combinedWord.length },
                    to: cursor,
                    list: monitorHints
                })
            });
        }
        const monitorRegex = /monitors\_\d+/;

        if (monitorRegex.test(currentWord) || monitorRegex.test(combinedWord)) {
            let functionHints = [
                { text: `monitors_${currentWord}_execute`, displayText: 'execute', docs: 'execute and get result instantly' },
                { text: `monitors_${currentWord}_log`, displayText: 'log', docs: 'get log of the monitor' },
            ];

            editor.showHint({
                hint: () => ({
                    from: { line: cursor.line, ch: cursor.ch - combinedWord.length },
                    to: cursor,
                    list: functionHints
                })
            });
        }
    }
}

export default showHints;