import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AlertGroupResponse} from '../models/alert-group/alert-group-response';
import {environment} from '../../environments/environment.dev';
import {Injectable} from '@angular/core';
import {ProjectRequest} from '../models/project/project-request';
import {ProjectsResponse} from '../models/project/projects-response';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  data: any[] = [];

  constructor(private httpClient: HttpClient) {
  }

  getAll(): Observable<ProjectRequest[]>{
    return this.httpClient.get<ProjectRequest[]>(environment.baseUri+'/projects');
  }
  updateProject(projectId: number | null, payload: any): Observable<any> {
    return this.httpClient.put(`${environment.baseUri}/project/${projectId}`, payload);
  }

  createProject(projectData: { name: string; description: string }): Observable<any> {
    return this.httpClient.post<any>(`${environment.baseUri}/project`, projectData);
  }

  getProjectById(projectId: string): Observable<ProjectsResponse> {
    return this.httpClient.get<ProjectsResponse>(`${environment.baseUri}/project/${projectId}`);
  }
}
