import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder, FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {NgClass, NgIf} from '@angular/common';
import {Card} from 'primeng/card';
import {InputText} from 'primeng/inputtext';
import {Button, ButtonDirective, ButtonLabel} from 'primeng/button';
import {Menubar} from 'primeng/menubar';
import {ConfirmationService, Footer, MessageService, PrimeTemplate} from 'primeng/api';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {Toast} from 'primeng/toast';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../services/users/users.service';
import {Password} from 'primeng/password';

@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [
    NgClass,
    Card,
    ReactiveFormsModule,
    NgIf,
    InputText,
    Button,
    Menubar,
    PrimeTemplate,
    ConfirmDialog,
    Toast,
    ButtonDirective,
    ButtonLabel,
    Password
  ],
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.scss',
  providers: [ConfirmationService, MessageService]

})
export class CreateUserComponent implements OnInit {
  passwordControl: FormControl;
  confirmPasswordControl: FormControl;

  userForm: FormGroup;
  isEditUser: boolean = false;
  userId: number | null = null;
  private sidebarSubscription !: Subscription;
  sidebarVisible !: boolean;
  isLoading: boolean = false

  constructor(private confirmationService: ConfirmationService,
              private userService: UsersService,
              private activatedRoute: ActivatedRoute,
              private messageService: MessageService,
              private router: Router, private fb: FormBuilder,
              private sidebarState: SidebarStateService) {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },{ validators: this.passwordMatchValidator() });
    this.passwordControl = this.userForm.get('password') as FormControl;
    this.confirmPasswordControl = this.userForm.get('confirmPassword') as FormControl;

  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )

    this.activatedRoute.paramMap.subscribe(params => {
      const userId = params.get('userId');
      if (userId) {
        this.isEditUser = true;
        console.log(this.isEditUser)
        this.userId = +userId;
        this.loadUserData();
        this.userForm.get('email')?.disable();
        this.userForm.get('username')?.disable();
        this.userForm.get('password')?.clearValidators();
        this.userForm.get('confirmPassword')?.clearValidators();
      } else {
        this.isEditUser = false;
        this.userId = null;
        this.userForm.get('password')?.setValidators([Validators.required]);
        this.userForm.get('confirmPassword')?.setValidators([Validators.required]);
      }
    });
  }

  saveUser() {
    console.log(this.userForm.value)
    if (!this.isEditUser) {
      this.adduser();
    } else this.updateUser();
  }

  adduser() {
    this.confirmationService.confirm({
      message: 'Do you want to add this record?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      rejectLabel: 'Cancel',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Add'
      },
      accept: () => {
        this.isLoading = true;
        this.userService.addUser(this.userForm.value).subscribe({
          next: value => {
            this.isLoading = false;
            this.messageService.add({severity: 'info', summary: 'Confirmed', detail: 'User created!'});
            this.router.navigate(['/users']);
          },
          error: err => {
            this.isLoading = false;
            this.messageService.add({severity: 'info', summary: 'Confirmed', detail: 'Something bad happened!'});
          }
        })
      },
      reject: () => {
        this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
      },
    });
  }

  updateUser() {
    this.confirmationService.confirm({
      message: "Do you want to update this record?",
      header: "Confirmation",
      icon: 'pi pi-info-circle',
      rejectLabel: 'Cancel',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Update'
      },
      accept: () => {
        this.isLoading = true;
        this.userService.updateUser(this.userId, this.userForm.value).subscribe({
          next: value => {
            this.isLoading = false;
            this.messageService.add({severity: 'info', summary: 'Confirmed', detail: 'User updated!'});
            this.router.navigate(['/users']);
          },
          error: err => {
            this.isLoading = false
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something went wrong!'});
          }
        })
      },
      reject: () => {
        this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
      },
    })
  }

  navigateToUsers() {
    this.confirmationService.confirm({
      message: '<div class="flex flex-column justify-content-center align-items-center"><span>Are you sure?</span><span>Your changes to the form will be lost!</span></div>',
      icon: 'pi pi-info-circle',
      header: 'Confirmation',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.router.navigate(['/users'])
      },
      reject: () => {
      },
      key: 'positionDialog'
    })
  }

  private loadUserData() {
    if (this.userId) {
      this.userService.getSingleUser(this.userId).subscribe(value => {
        this.userForm.patchValue(
          {
            name: value.name,
            email: value.email,
            username: value.username,
            mobile: value.mobile,
          }
        )
      })
    }
  }

  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;

      const password = formGroup.get('password');
      const confirmPassword = formGroup.get('confirmPassword');

      if (password && confirmPassword && password.value !== confirmPassword.value) {
        return {passwordMismatch: true};
      }
      return null;
    };
  }
}
