import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {InputText} from 'primeng/inputtext';
import {MultiSelect} from 'primeng/multiselect';
import {Select} from 'primeng/select';
import {Operator, OperatorMap, ResponseValidationRequest} from '../../../models/response-validation/requests';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {Button, ButtonDirective, ButtonIcon, ButtonLabel} from 'primeng/button';
import {NgClass, NgForOf} from '@angular/common';
import {Tooltip} from 'primeng/tooltip';
import {ResultValidationArg} from '../../../models/response-validation/responses';

@Component({
  selector: 'app-response-validation-input',
  standalone: true,
  imports: [
    InputText,
    MultiSelect,
    Select,
    FormsModule,
    ReactiveFormsModule,
    ButtonDirective,
    ButtonLabel,
    ButtonIcon,
    NgForOf,
    Button,
    Tooltip,
    NgClass
  ],
  templateUrl: './response-validation-input.component.html',
  styleUrl: './response-validation-input.component.scss'
})
export class ResponseValidationInputComponent implements OnChanges{

  private fb: FormBuilder = inject(FormBuilder);
  formGroup: FormGroup = this.fb.group({
    formsArray: this.fb.array([
      this.fb.group({
        keyPath: new FormControl('', [Validators.required]),
        operator: new FormControl('', [Validators.required]),
        value: new FormControl('', [Validators.required])
      })
    ])
  });
  @Input() resultValidationArgList: ResultValidationArg[] = []
  operators: any[] = [
    {name: OperatorMap[Operator.EQ], code: Operator.EQ},
    {name: OperatorMap[Operator.GT], code: Operator.GT},
    {name: OperatorMap[Operator.LT], code: Operator.LT},
    {name: OperatorMap[Operator.CONTAINS], code: Operator.CONTAINS},
    {name: OperatorMap[Operator.NOT_CONTAINS], code: Operator.NOT_CONTAINS}
  ]

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.resultValidationArgList)
    if(this.resultValidationArgList?.length > 0){
      this.formsArray.controls = []
      this.resultValidationArgList.forEach(arg => {
        this.formsArray.controls.push(this.fb.group(arg));
      })
    }
  }

  get formsArray() : FormArray{
    return this.formGroup.get('formsArray') as FormArray;
  }

  addForm(){
    const form = this.fb.group({
      keyPath: new FormControl('', [Validators.required]),
      operator: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required])
    })
    console.log('formarray:', this.formsArray)
    this.formsArray.push(form)
  }

  removeForm(index: number) {
    if (this.formsArray.length > 1){
      this.formsArray.removeAt(index);
    }
  }
}
