<p-table *ngIf="loading" [value]="skeletonRows" size="small">
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let header of headers">{{ header }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-skeleton>
    <tr>
      <td *ngFor="let _ of headers">
        <p-skeleton></p-skeleton>
      </td>
    </tr>
  </ng-template>
</p-table>
