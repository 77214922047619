import {HttpClient, HttpParams} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {HttpMonitorPayload} from '../../../models/monitors/monitor-requests';
import {HttpMonitorResponse} from '../../../models/monitors/monitor-responses';
import { environment } from '../../../../environments/environment.dev';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpMonitorServiceService {

  private httpClient: HttpClient = inject(HttpClient);

  create(payload: HttpMonitorPayload, projectId: string): Observable<HttpMonitorResponse> {
    const url = `${environment.baseUri}/http-monitor?projectId=${projectId}`;
    return this.httpClient.post<HttpMonitorResponse>(url, payload);
  }

  get(id: number): Observable<HttpMonitorResponse>{
    return this.httpClient.get<HttpMonitorResponse>(environment.baseUri+'/http-monitor/'+id);
  }

  update(id: number, payload: HttpMonitorPayload, projectId:string): Observable<HttpMonitorResponse> {
    const params = new HttpParams().set('projectId', projectId);
    return this.httpClient.put<HttpMonitorResponse>(environment.baseUri+`/http-monitor/${id}`, payload, {params})
  }

}
