<p-dialog header="Integrate Email" [modal]="true" (onHide)="dialogClose()" [(visible)]="visible" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
  <div class="flex align-items-center gap-4 ">
    <label for="email" class="font-semibold w-24">Email Address</label>
    <input pInputText id="email" type="email" [formControl]="emailAddress" [email]="true" class="flex-auto" autocomplete="off" />

  </div>
  <div class="flex align-items-center justify-content-center text-red-500 mb-4">
    @if(emailAddress.touched && emailAddress.invalid){
      @if(emailAddress.hasError('required')){
        <small class="text-red-500">Email is required.</small>
      }
      @else if(emailAddress.hasError('email')) {
        <small class="text-red-500">Invalid email format.</small>
      }
    }
  </div>

  <div class="flex justify-end gap-2">
    <p-button label="Cancel" severity="secondary" (click)="visible = false" />
    <p-button label="Save" (click)="createEmailIntegration()" [loading]="loading"/>
  </div>
</p-dialog>
