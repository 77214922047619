import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CardModule } from 'primeng/card';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {SelectButton} from 'primeng/selectbutton'
import { ChipModule } from 'primeng/chip';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SelectModule } from 'primeng/select';
import { TextareaModule } from 'primeng/textarea';
import {ExpectedHttpResponses, HttpMethods, ScheduleIntervals} from '../../../models/enums';
import { MultiSelectModule } from 'primeng/multiselect';
import { HttpMonitorPayload } from '../../../models/monitors/monitor-requests';
import { HttpMonitorResponse } from '../../../models/monitors/monitor-responses';
import { HttpMonitorServiceService } from '../../../services/monitor/http/http-monitor-service.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ValidationService } from '../../../services/shared/validation/validation.service';
import {
  ResponseValidationInputComponent
} from '../../response-validation/response-validation-input/response-validation-input.component';
import {NgForOf} from '@angular/common';
import {ResultValidationArg} from '../../../models/response-validation/responses';
import { AlertGroupResponse } from '../../../models/alert-group/alert-group-response';
import {MonitorService} from '../../../services/monitor/monitor.service';

@Component({
  selector: 'app-create-http-monitor',
  standalone: true,
  imports: [ChipModule, CardModule, FormsModule, ChipModule, AccordionModule, DropdownModule, ButtonModule, InputTextModule,
    FormsModule, ReactiveFormsModule, SelectModule, SelectButton, TextareaModule, MultiSelectModule, ResponseValidationInputComponent, NgForOf],
  templateUrl: './create-http-monitor.component.html',
  styleUrl: './create-http-monitor.component.scss'
})
export class CreateHttpMonitorComponent implements OnInit{
  loading: boolean = false

  private httpMonitorService: HttpMonitorServiceService = inject(HttpMonitorServiceService);
  private messageService: MessageService = inject(MessageService);
  private monitorService: MonitorService = inject(MonitorService);
  private router: Router = inject(Router);
  private customValidator = inject(ValidationService);

  httpMonitorPayload: FormGroup = new FormGroup({
    httpUrl: new FormControl('', this.customValidator.urlValidator),
    name: new FormControl('', Validators.required),
    scheduleInterval: new FormControl('', Validators.required),
    httpMethod: new FormControl('', Validators.required),
    expectedResponse: new FormControl([]),
    disableScheduler: new FormControl(false),
    alertGroupIds: new FormControl([]),
    selectedProject: new FormControl([]),
  })
  intervals: any[] = [
  { name: '1 minute', code: ScheduleIntervals['1 minute'] },
  { name: '5 minutes', code: ScheduleIntervals['5 minutes'] },
  { name: '30 minutes', code: ScheduleIntervals['30 minutes'] },
  { name: '1 hour', code: ScheduleIntervals['1 hour'] },
  { name: '5 hours', code: ScheduleIntervals['5 hours'] },
  { name: '12 hours', code: ScheduleIntervals['12 hours'] },
  { name: '24 hours', code: ScheduleIntervals['24 hours'] }
  ];
  schedulerOptions: any[] = [
    {name: 'On', code: false},
    {name: 'Off', code: true}
  ]
  httpMethods: string[] = Object.values(HttpMethods);
  values: any[] = [
    {name: '200', code: 200}
  ]
  @Input() selectedMonitorForEditId!: number;
  @Input() editMode: boolean = false;
  private selectedMonitorForEdit!: HttpMonitorResponse;

  resultValidationArgs!: ResultValidationArg[];
  @ViewChild(ResponseValidationInputComponent) responseValidationInputChild!: ResponseValidationInputComponent;

  @Output() alertGroupIdsEvent: EventEmitter<number[]> = new EventEmitter<number[]>();

  ngOnInit(): void {
    this.loadEditForm();
  }

  loadEditForm() {
    if (this.editMode && this.selectedMonitorForEditId) {
      this.loading = true;
      this.httpMonitorService.get(this.selectedMonitorForEditId).subscribe({
          next: (res) => {
            this.selectedMonitorForEdit = res
            console.log(this.selectedMonitorForEdit,'res,,,,')
          },
          error: (err) => {
            this.loading = false;
            console.error('Err editing monitor', err);
          },
          complete: () => {
            this.loading = false;
            this.httpMonitorPayload.controls['httpUrl'].setValue(this.selectedMonitorForEdit.httpUrl);
            this.httpMonitorPayload.controls['name'].setValue(this.selectedMonitorForEdit.name);
            this.httpMonitorPayload.controls['scheduleInterval'].setValue(this.selectedMonitorForEdit.scheduleInterval)
            this.httpMonitorPayload.controls['httpMethod'].setValue(this.selectedMonitorForEdit.httpMethod)
            this.httpMonitorPayload.controls['expectedResponse'].setValue([this.selectedMonitorForEdit.expectedResponse])
            this.httpMonitorPayload.controls['disableScheduler'].setValue(!this.selectedMonitorForEdit.active)
            this.monitorService.setSelectedProject(this.selectedMonitorForEdit.project);

            if (this.selectedMonitorForEdit.resultValidationResponse) {
              this.resultValidationArgs = this.selectedMonitorForEdit.resultValidationResponse.resultValidationArgs;
            } else {
              console.warn('resultValidationResponse is null or undefined.');
              this.resultValidationArgs = [];
            }
            const alertGroupIds = this.selectedMonitorForEdit.alertGroups.map(group => group.id);
            this.httpMonitorPayload.controls['alertGroupIds'].setValue(alertGroupIds);
            console.log('Alert Group IDs:', alertGroupIds);
            this.alertGroupIdsEvent.emit(alertGroupIds)
          }
        })
      }
  }

  submitForm(alertGroupIds: number[], selectedProject: any) {
    this.loading = true;

    this.httpMonitorPayload.controls['alertGroupIds'].setValue(alertGroupIds);

    if (!this.httpMonitorPayload.valid) {
      this.httpMonitorPayload.markAllAsTouched();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Form contains errors!' });
      this.loading = false;
      return;
    }

    const projectId = selectedProject?.id;

    if (this.editMode) {
      this.update(projectId);
    } else {
      this.create(projectId);
    }
  }

  create(projectId: string) {
    let form = this.httpMonitorPayload.getRawValue();
    let argList: ResultValidationArg[] = [];

    this.responseValidationInputChild.formsArray.controls.forEach(control => {
      const arg: ResultValidationArg = control.getRawValue();
      if (!(arg.keyPath === '' && arg.operator === '' && arg.value === '')) {
        argList.push(arg);
      }
    });

    const payload: HttpMonitorPayload = {
      name: form['name'],
      httpUrl: form['httpUrl'],
      labelIds: [],
      disableScheduler: form['disableScheduler'],
      scheduleInterval: form['scheduleInterval'],
      expectedResponse: form['expectedResponse'][0],
      httpMethod: form['httpMethod'],
      validationArgsList: argList,
      alertGroupIds: form['alertGroupIds'],
    };
    this.httpMonitorService.create(payload, projectId).subscribe({
      next: (res) => {
        console.log('res', res);
      },
      error: (error) => {
        console.error('error', error);
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating SSL monitor' });
      },
      complete: () => {
        console.log('complete');
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Monitor Created Successfully!' });
        setTimeout(() => {
          this.loading = false;
          this.httpMonitorPayload.reset();
          this.router.navigate(['/monitors']);
        }, 1000);
      },
    });
  }


  update(projectId:string){
    let form = this.httpMonitorPayload.getRawValue();
    let argList: ResultValidationArg[] = []
    this.responseValidationInputChild.formsArray.controls.forEach(control => {
      const arg: ResultValidationArg = control.getRawValue();
      if (!(arg.keyPath === '' && arg.operator === '' && arg.value === '')) {
        argList.push(arg);
      }
    })
    console.log('args', argList)
      const payload: HttpMonitorPayload = {
        name: form['name'],
        httpUrl: form['httpUrl'],
        labelIds: [],
        disableScheduler: form['disableScheduler'],
        scheduleInterval: form['scheduleInterval'],
        expectedResponse: form['expectedResponse'][0],
        httpMethod: form['httpMethod'],
        validationArgsList: argList,
        alertGroupIds: form['alertGroupIds']
      }
    this.httpMonitorService.update(this.selectedMonitorForEditId,payload,projectId).subscribe({
      next: (res) => {
        console.log('res', res);
      },
      error:(error) => {
        console.error('error', error)
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating SSL monitor' });
      },
      complete: () => {
        console.log('complete')
        this.httpMonitorPayload.reset();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Monitor Updated Successfully!'});
        setTimeout(() => {
          this.loading = false;
          this.router.navigate(['/monitors']);
        }, 1000);
      }
    });
  }
}
