import * as i0 from '@angular/core';
import { Injectable, Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';
const _forTrack0 = ($index, $item) => $item.id;
const _c0 = (a0, a1) => ({
  "background-color": a0,
  "border-radius": a1
});
function ColouredStatsBarComponent_For_3_Conditional_1_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const key_r4 = ctx.$implicit;
    const item_r2 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2("", key_r4, ": ", item_r2.toolTipData[key_r4], "");
  }
}
function ColouredStatsBarComponent_For_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵrepeaterCreate(1, ColouredStatsBarComponent_For_3_Conditional_1_For_2_Template, 2, 2, "div", null, i0.ɵɵrepeaterTrackByIdentity);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r2.getKeys(item_r2.toolTipData));
  }
}
function ColouredStatsBarComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵlistener("mouseover", function ColouredStatsBarComponent_For_3_Template_div_mouseover_0_listener() {
      const item_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.showTooltip(item_r2.id));
    })("mouseout", function ColouredStatsBarComponent_For_3_Template_div_mouseout_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.hideTooltip());
    });
    i0.ɵɵtemplate(1, ColouredStatsBarComponent_For_3_Conditional_1_Template, 3, 0, "div", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", item_r2.id)("ngStyle", i0.ɵɵpureFunction2(3, _c0, item_r2.stat === null ? "gray" : item_r2.stat <= ctx_r2.statsBarInput.markRedAt ? "rgba(238, 68, 68)" : "rgba(32, 185, 88, " + item_r2.opacity + ")", ctx_r2.getBorderRadius(item_r2)));
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r2.showToolTip && ctx_r2.tooltipId == item_r2.id ? 1 : -1);
  }
}
class CarviaService {
  constructor() {}
  static {
    this.ɵfac = function CarviaService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CarviaService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CarviaService,
      factory: CarviaService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarviaService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class CarviaComponent {
  static {
    this.ɵfac = function CarviaComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CarviaComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CarviaComponent,
      selectors: [["lib-carvia"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 0,
      template: function CarviaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "p");
          i0.ɵɵtext(1, " carvia works! ");
          i0.ɵɵelementEnd();
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarviaComponent, [{
    type: Component,
    args: [{
      selector: 'lib-carvia',
      standalone: true,
      imports: [],
      template: `
    <p>
      carvia works!
    </p>
  `
    }]
  }], null, null);
})();
class ColouredStatsBarComponent {
  constructor() {
    this.tooltipId = 0;
    this.showToolTip = false;
  }
  ngOnInit() {
    console.log('statsBarInput', this.statsBarInput);
    if (!this.statsBarInput) {
      throw new Error('statsBarInput is required');
    }
  }
  getBorderRadius(item) {
    const firstItem = this.statsBarInput.bars[0];
    const lastItem = this.statsBarInput.bars[this.statsBarInput.bars.length - 1];
    if (item.id === firstItem.id) {
      return '1rem 0 0 1rem';
    } else if (item.id === lastItem.id) {
      return '0 1rem 1rem 0';
    }
    return '0';
  }
  showTooltip(id) {
    console.log('showTooltip', id);
    this.tooltipId = id;
    this.showToolTip = true;
  }
  hideTooltip() {
    console.log('hideTooltip');
    this.tooltipId = 0;
    this.showToolTip = false;
  }
  getKeys(extras) {
    return Object.keys(extras);
  }
  static {
    this.ɵfac = function ColouredStatsBarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ColouredStatsBarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ColouredStatsBarComponent,
      selectors: [["carvia-coloured-stats-bar"]],
      inputs: {
        statsBarInput: "statsBarInput"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 0,
      consts: [[1, "color-coded-stats-bar"], [1, "bar-container"], [1, "bar", 3, "id", "ngStyle"], [1, "bar", 3, "mouseover", "mouseout", "id", "ngStyle"], [1, "tooltip"]],
      template: function ColouredStatsBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵrepeaterCreate(2, ColouredStatsBarComponent_For_3_Template, 2, 6, "div", 2, _forTrack0);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.statsBarInput.bars);
        }
      },
      dependencies: [NgStyle],
      styles: [".bar[_ngcontent-%COMP%]{width:.4rem;height:3rem;cursor:pointer}.bar-container[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:row;gap:.3rem}.color-coded-stats-bar[_ngcontent-%COMP%]{display:flex;flex-direction:row;gap:1rem}.bar-container[_ngcontent-%COMP%], .bar[_ngcontent-%COMP%]{position:relative}.tooltip[_ngcontent-%COMP%]{position:absolute;top:-30px;left:50%;transform:translate(-50%);background-color:#000000b3;color:#fff;padding:5px;border-radius:5px;font-size:12px;white-space:nowrap;z-index:2}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColouredStatsBarComponent, [{
    type: Component,
    args: [{
      selector: 'carvia-coloured-stats-bar',
      standalone: true,
      imports: [NgStyle],
      template: "<div class=\"color-coded-stats-bar\">\n  <div class=\"bar-container\">\n    @for (item of statsBarInput.bars; track item.id){\n      <div [id]=\"item.id\"\n           class=\"bar\"\n           (mouseover)=\"showTooltip(item.id)\"\n           (mouseout)=\"hideTooltip()\"\n           [ngStyle]=\"{\n            'background-color': item.stat === null\n              ? 'gray'\n              : item.stat <= statsBarInput.markRedAt\n                ? 'rgba(238, 68, 68)'\n                : 'rgba(32, 185, 88, ' + item.opacity + ')',\n            'border-radius': getBorderRadius(item)\n          }\"\n      >\n        @if (showToolTip && tooltipId==item.id){\n          <div class=\"tooltip\">\n            @for (key of getKeys(item.toolTipData); track key) {\n              <div>{{ key }}: {{ item.toolTipData[key] }}</div>\n            }\n          </div>\n        }\n      </div>\n    }\n  </div>\n</div>\n",
      styles: [".bar{width:.4rem;height:3rem;cursor:pointer}.bar-container{width:100%;display:flex;flex-direction:row;gap:.3rem}.color-coded-stats-bar{display:flex;flex-direction:row;gap:1rem}.bar-container,.bar{position:relative}.tooltip{position:absolute;top:-30px;left:50%;transform:translate(-50%);background-color:#000000b3;color:#fff;padding:5px;border-radius:5px;font-size:12px;white-space:nowrap;z-index:2}\n"]
    }]
  }], null, {
    statsBarInput: [{
      type: Input
    }]
  });
})();

/*
 * Public API Surface of carvia
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CarviaComponent, CarviaService, ColouredStatsBarComponent };
