import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IntegrationService} from '../../../../services/integrations/integration.service';
import {AuthType, SlackIntegrationPayload} from '../../../../models/integrations/integration-requests';
import {environment} from '../../../../../environments/environment.dev';

@Component({
  selector: 'app-slack-oauth',
  standalone: true,
  imports: [],
  template: `<p>Redirecting...</p>`,
})
export class SlackOauthComponent implements OnInit{
  private route: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private integrationService: IntegrationService = inject(IntegrationService);
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code'] as string;
      const state = params['state'];
      let payload: SlackIntegrationPayload = {
        redirectUri: environment.slack.redirectUri,
        code: code,
        authType: AuthType.SLACK
      }
      console.log('received code, sliced:', code.slice(0,4));
      this.integrationService.createSlackIntegration(payload).subscribe({
        next: (next) => {
        },
        error: err => console.error(err),
        complete: () => {
          this.router.navigate(["integrations"], {queryParams: {args: 'SLACK'}})
          console.log('sent code to BE');
        }
      });
    })
  }
}
