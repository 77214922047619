import { Component } from '@angular/core';
import {DatePipe, NgClass} from '@angular/common';
import {Subscription} from 'rxjs';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {MonitorService} from '../../services/monitor/monitor.service';
import {MessageService, PrimeTemplate} from 'primeng/api';
import {Page} from '../../models/commons';
import {DashboardService} from '../../services/dashboardService';
import {FilteredIncidents} from '../../incident/incident-request';
import {TableModule} from 'primeng/table';
import {FormsModule} from '@angular/forms';
import {InputText} from 'primeng/inputtext';
import {Menubar} from 'primeng/menubar';
import {Select} from 'primeng/select';
import {ProjectService} from '../../services/projectService';
import {Tag} from 'primeng/tag';
import {ProjectRequest} from '../../models/project/project-request';
import { TooltipModule } from 'primeng/tooltip';
import { DurationPipe } from '../../pipes/duration-pipe/duration.pipe';
import {SkeletonTableComponent} from '../../components/common/skeleton-table/skeleton-table.component';

@Component({
  selector: 'app-incidents',
  standalone: true,
  imports: [
    NgClass,
    PrimeTemplate,
    TableModule,
    FormsModule,
    InputText,
    Menubar,
    Select,
    DatePipe,
    Tag,
    TooltipModule,
    DurationPipe,
    SkeletonTableComponent
  ],
  templateUrl: './incidents.component.html',
  styleUrl: './incidents.component.scss'
})
export class IncidentsComponent {
  sidebarSubscription !: Subscription;
  sidebarVisible !: boolean;
  page: Page = { size: 10, totalElements: 0, totalPages: 0, number: 0 };
  isLoading: boolean = false
  projectId: string = '';
  status: string = '';
  incidentsData: FilteredIncidents[] = [];
  selectedProject: string='';
  selectedStatus: string = '';
  searchInputText: string = '';
  projects: ProjectRequest[] = []
  loading = true;
  incidentHeader: string[] = []

  statusOptions = [
    { label: 'Ongoing', value: 'ONGOING' },
    { label: 'Resolved', value: 'RESOLVED' },
  ];
  constructor(private sidebarState: SidebarStateService,private dashboardService: DashboardService, private projectService:ProjectService, private monitorService:MonitorService) {
  }
  ngOnInit(): void {
    this.incidentHeader = ['Status', 'Monitor Name', 'Root Cause','Project Name','Started','Duration (HH:MM:SS)'];
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      (visible) => {
        this.sidebarVisible = visible;
      }
    );
    this.monitorService.selectedProject$.subscribe(project => {
      this.selectedProject = project;
      console.log('Selected Project:', this.selectedProject);
    });
    this.getProjects();
    this.loadIncidents({page: 0, size: this.page.size})
  }
  onProjectSelect(project: any): void {
    this.selectedProject = project;
    this.projectId = project ? project.id : null;
    this.loadIncidents({ first: 0, rows: this.page.size });
  }

  onStatusChange(status: any): void {
    this.selectedStatus = status;
    this.status = status;
    this.loadIncidents({ first: 0, rows: this.page.size });
  }
  loadIncidents(event: any): void {
    this.isLoading = true;

    const pageIndex = event.first ? event.first / event.rows : 0;
    const pageSize = event.rows || this.page.size;

    console.log(
      `Loading incidents: projectId="${this.projectId || 'ALL'}", status="${
        this.status || 'ALL'
      }", page=${pageIndex}, size=${pageSize}`
    );

    this.dashboardService
      .getProjectFilteredIncidents(
        pageIndex,
        pageSize,
        this.projectId || '',
        this.status || ''
      )
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          const { content, page } = response;
          this.incidentsData = content;
          this.page = page;
          this.loading = false;

        },
        error: (err) => {
          this.isLoading = false;
          console.log('Error fetching incidents:', err);
        },
      });
  }
  onPageChange(event: any): void {
    console.log("changing page: ", event.first, ",", event.rows)
    this.loadIncidents(event);
  }
  getProjects(){
    this.projectService.getAll().subscribe({
      next: data => {
        this.projects = data;
      },
      error: error => {
        console.log(error);
      },
      complete: () => {
      }
    })
  }

}
