import {CommonModule} from '@angular/common';
import {Component, inject, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {FloatLabelModule} from 'primeng/floatlabel';
import {MenubarModule} from 'primeng/menubar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {DataViewModule} from 'primeng/dataview';
import {MonitorCardComponent} from '../../components/monitor-card/monitor-card.component';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Select} from 'primeng/select';
import {TableModule} from 'primeng/table';
import {AlertGroupService} from '../../services/alert-group/alert-group.service';
import {AlertGroupResponse} from '../../models/alert-group/alert-group-response';
import {TieredMenu} from 'primeng/tieredmenu';
import {MenuItem} from 'primeng/api';
import {Menu} from 'primeng/menu';
import {SkeletonTableComponent} from "../../components/common/skeleton-table/skeleton-table.component";


@Component({
  selector: 'app-monitors',
  standalone: true,
    imports: [CommonModule, MenubarModule, ButtonModule, FloatLabelModule, FormsModule, DropdownModule, InputTextModule, DataViewModule, MonitorCardComponent, Select, TableModule, TieredMenu, Menu, SkeletonTableComponent,],      // For p-table],
  templateUrl: './alert-listing.component.html',
  styleUrl: './alert-listing.component.scss'
})
export class AlertListingComponent implements OnInit {
  sidebarVisible !: boolean;
  loading: boolean = false;
  private sidebarSubscription !: Subscription;
  searchInputText: string = '';
  private sidebarState : SidebarStateService = inject(SidebarStateService);
  private router: Router = inject(Router);
  private alertGroupService: AlertGroupService = inject(AlertGroupService);
  alertGroups: AlertGroupResponse[] = []
  actionItemsCache = new Map<string, MenuItem[]>();
  items: MenuItem[] = [];
  alertsHeader: string[] = []

  navigateToCreateAlert() {
    this.router.navigate(['/alerts/create']);
  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.alertsHeader = ['Name', 'Description', 'Created On','Action'];

    this.loadAlertGroups()
  }

  loadAlertGroups(){
    this.loading = true;
    this.alertGroupService.getAll().subscribe({
      next: data => {
        this.alertGroups = data;
      },
      error: error => {
        console.log(error);
      },
      complete: () => {
        this.loading = false
      }
    })
  }




  goToCreateAlertPage(alertId: string){
    this.router.navigate(['/alerts/create'], {
      queryParams: { alertId }
    });
  }


  showMenu($event: MouseEvent, menu: Menu, alertGroupId: string) {
    if(!alertGroupId){
      console.error('Inalid alertGroupId')
    }

    this.items = [
      {
        label: 'Edit Alert Group',
        icon: 'pi pi-pencil',
        command: () => {
          this.goToCreateAlertPage(alertGroupId);
        },
      },
    ];

    menu.toggle($event)

  }
}
