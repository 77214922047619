import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {Button, ButtonDirective} from 'primeng/button';
import {CommonModule, DatePipe, NgClass} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputText} from 'primeng/inputtext';
import {Menubar} from 'primeng/menubar';
import {ConfirmationService, MenuItem, MessageService, PrimeTemplate} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {Subscription} from 'rxjs';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import { SelectModule } from 'primeng/select';
import {
  CreateEmailIntegrationComponent
} from '../../components/create-email-integration/create-email-integration.component';
import {Dialog} from 'primeng/dialog';
import {Toast, ToastModule} from 'primeng/toast';
import {IntegrationResponse} from '../../models/integrations/integration-response';
import {IntegrationService} from '../../services/integrations/integration.service';
import {ProgressSpinner} from 'primeng/progressspinner';
import {SlackOauthService} from '../../login/integrations/slack/slack-oauth/service/slack-oauth.service';
import {
  CreateSlackIntegrationComponent
} from '../../components/create-slack-integration/create-slack-integration.component';
import {Menu} from 'primeng/menu';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {SkeletonTableComponent} from '../../components/common/skeleton-table/skeleton-table.component';

@Component({
  selector: 'app-integrations',
  standalone: true,
  imports: [
    Button,
    ButtonDirective,
    DatePipe,
    FormsModule,
    InputText,
    Menubar,
    PrimeTemplate,
    TableModule,
    NgClass,
    SelectModule,
    CommonModule,
    CreateEmailIntegrationComponent,
    Dialog,
    Toast,
    ProgressSpinner,
    CreateSlackIntegrationComponent,
    Menu,
    ConfirmDialog,
    SkeletonTableComponent
  ],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss'
})
export class IntegrationsComponent implements OnInit{
  sidebarVisible !: boolean;
  private sidebarSubscription !: Subscription;
  searchInputText: string = '';
  private sidebarState : SidebarStateService = inject(SidebarStateService);
  private router: Router = inject(Router);
  private integrationService: IntegrationService = inject(IntegrationService);
  private messageService: MessageService = inject(MessageService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private confirmationService: ConfirmationService = inject(ConfirmationService);

  loading: boolean = false;
  types: any;
  selectedType: any;
  integrationOptions: string[] = ['Slack','Email']
  selectedIntegration!: string;
  integrations: IntegrationResponse[] = [];
  menuItems!: MenuItem[];
  selectedIntegrationId!:number;

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.activatedRoute.queryParams.subscribe(params => {
      switch (params['args']){
        case 'SLACK':
          console.log('Created Successfully')
          // TODO: make this toast work
          const messageService = new MessageService();
          messageService.add({severity: 'success', summary: 'Success', detail: 'Successfully created slack integration', life:30000});
      }
    })
    this.loadIntegrations();
  }

  loadIntegrations(){
    this.loading = true;
    this.integrationService.getAll().subscribe({
      next: res => {
        console.log(res)
        this.integrations = res.content;
      },
      error: err => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error loading Integrations!'})
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  showMenu(event: Event, menu: any, integration: IntegrationResponse ) {
    this.selectedIntegrationId = integration.id;

    this.menuItems = [
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.deleteRecord(integration),
      },

    ];
    console.log("mouse event", event);
    menu.toggle(event);
  }


  deleteRecord(record: any) {
    console.log('Delete record:', record);
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      rejectLabel: 'Cancel',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Delete',
        severity: 'danger',
      },
      accept: () => {
        console.log("Deleting integration with id:", record.id);
        this.deleteIntegration(record.id);
      },
      reject: () => {
        this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
      },
    });
  }

  deleteIntegration(integrationId: number): void {
    this.integrationService.deleteIntegration(integrationId).subscribe({
      next: value => {
        this.messageService.add({severity: 'info', summary: 'Confirmed', detail: 'Integration deleted!'});
        this.loadIntegrations();

      },
      error: err => {
        console.log("Something bad happened!");
        this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'Something bad happened!'});
      }
    })
  }

  newEmailIntegrationCreated($event: IntegrationResponse) {
    this.loadIntegrations()
  }

  emailCreationCancelled($event: boolean) {
    this.selectedIntegration = ''
  }
}
