<div class="monitors-detail-screen flex flex-column gap-2 overflow-x-auto " [ngClass]="{'reduced': sidebarVisible}">
  <p-menubar class="menu">
    <ng-template pTemplate="start">
      <div (click)="backToMonitorPage()" class="go-back">
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.57578 2.55965C6.84033 2.2951 6.84033 1.86619 6.57578 1.60164C6.31123 1.33709 5.88232 1.33709 5.61777 1.60164L0.198412 7.02099C-0.0661372 7.28554 -0.0661372 7.71446 0.198412 7.97901L5.61777 13.3984C5.88232 13.6629 6.31123 13.6629 6.57578 13.3984C6.84033 13.1338 6.84033 12.7049 6.57578 12.4403L2.31285 8.17742H13.3226C13.6967 8.17742 14 7.87413 14 7.5C14 7.12587 13.6967 6.82258 13.3226 6.82258H2.31285L6.57578 2.55965Z"
                fill="#334155"/>
        </svg>
        <span class="text-500-14px">go back</span>
      </div>
    </ng-template>
  </p-menubar>
  <div class="monitor-card m-2">
    <div class="header text-500-20px">
      <span class="text-500-20px">
        @if (monitorData.status == 'DOWN') {
          <i class="pi pi-chevron-circle-down lg:font-bold cursor-pointer border-round-xl lg:text-2xl bg-red-50 "></i>
        } @else {
          <i class="pi pi-chevron-circle-up lg:font-bold cursor-pointer border-round-xl lg:text-2xl bg-red-50"></i>
        }
        {{ httpUrl }}</span>
    </div>
    <div class="text-500-16px mt-2">
      HTTP/S monitor for
      <a [href]="httpUrl" target="_blank" rel="noopener noreferrer" class="text-green-50-500 underline">
        {{ httpUrl }}
      </a>
    </div>

    <div class="status-section">
      <div class="generic-card-monitor gap-4 p-5 text-500-14px">
        <div>
          <span>Current status: </span>
          <span [class]="monitorData.status === 'DOWN' ? 'status-down' : 'status-up'">
            {{ monitorData.status }}
          </span>
        </div>
        <div>
          <span class="text-500-14px">Currently {{ monitorData.status }} for {{ monitorData.currentStatusDuration | duration }}</span>
        </div>
      </div>
      <div class="generic-card-monitor">
        <div>
          <span class="text-500-14px">Last check: {{ monitorData.monitorStatusCheckedAt | date: 'MMM d, y HH:mm:ss'  }}</span>
        </div>
        <!-- <h3></h3> -->
        <div>
            <span class="text-500-16px">
              Checked every {{ monitorData?.scheduleInterval?.replace('_', ' ').toLowerCase() || 'not available' }}
            </span>
        </div>
      </div>
      <div class="generic-card-monitor">
        <div class="monitor-header">
          <span class="text-500-14px"> Last 24 hours</span>
          <h3>{{ monitorData?.monitor24HourStatsDto?.uptimePercentage ? monitorData?.monitor24HourStatsDto?.uptimePercentage + '%' : '0.00%' }}</h3>
        </div>
        <carvia-coloured-stats-bar [statsBarInput]="graphInput"></carvia-coloured-stats-bar>
        <p>{{ monitorData?.monitor24HourStatsDto?.incidentCount }} Incident</p>
      </div>
    </div>

    <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
      <p-tabPanel header="Incidents">
        <div class="statistics">
          @if (shimmerLoading) {
            <app-skeleton-table [loading]="shimmerLoading" [headers]="incidenceHeaders"></app-skeleton-table>
          }
          @if (incidenceData.length > 0) {
            <p-table
              [value]="incidenceData"
              [first]="incidencePage.number * incidencePage.size"
              stripedRows
              [paginator]="incidenceData.length >0"
              [rows]="incidencePage.size"
              [showCurrentPageReport]="incidenceData.length >0"
              [tableStyle]="{ 'min-width': '50rem' }"
              [lazy]="true"
              size="small"
              (onPage)="onIncidencePageChange($event)"
              [totalRecords]="incidencePage.totalElements"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[5,10,25,50]"
              class="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                  @for (incidenceHeader of incidenceHeaders; track incidenceHeaders){
                  <th>{{ incidenceHeader }}</th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr class="text-500-16px">
                  <td>
                    <p-tag
                      [value]="item.status"
                        [severity]="item.status === 'ONGOING' ? 'danger' : (item.status === 'RESOLVED' ? 'success' : 'info')">
                    </p-tag>
                  </td>
                  <td showDelay="100" pTooltip="{{item.rootCause}}">{{ item.rootCause.substring(0,100)}}...</td>
                  <td>{{ item.createdDate | date }}</td>
                  <td>{{ item.incidentDuration | duration }}</td>
                </tr>
              </ng-template>


            </p-table>
          } @else if (incidenceData.length == 0 && !loading) {
            <div style="text-align: center;">
              <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_814_160)">
                  <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_814_160">
                    <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg></div>
              <div class=" flex justify-content-center text-2xl my-2">
                No Data Found
              </div>
              <div class="info-2 flex justify-content-center text-xl my-2">
                There is no incidents. Everything seems to be working fine
              </div>

            </div>
          }
        </div>

      </p-tabPanel>
      <p-tabPanel header="Logs">
        @if (shimmerLoading) {
          <app-skeleton-table [loading]="shimmerLoading" [headers]="logsHeader"></app-skeleton-table>
        }
        @if (logsData.length > 0) {
          <p-table
            [value]="logsData"
            [first]="logsPage.number * logsPage.size"
            stripedRows
            [paginator]="true"
            [rows]="logsPage.size"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            [lazy]="true"
            (onPage)="onLogsPageChange($event)"
            [totalRecords]="logsPage.totalElements"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[5,10,25,50]"
            dataKey="id"
            size="small"
            [expandedRowKeys]="expandedRowKeys"
            (onRowExpand)="onRowExpand($event)"
            (onRowCollapse)="onRowCollapse($event)"
            class="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                @for (header of logsHeader; track header){
                  <th>{{ header }}</th>
                }
              </tr>

            </ng-template>
            <ng-template pTemplate="body" let-item let-expanded="expanded">
              <tr class="cursor-pointer " [pRowToggler]="item">
                <td class="text-500-16px">{{ item.createdDate | date: 'dd-MM-yyyy HH:mm' }}</td>
                <td class="text-500-16px">{{ item.executionTime | duration}}</td>
                <td class="text-500-16px">
                  {{
                    item.responseCode === 200
                      ? item.responseCode
                      : item.responseCode + ' (' + (item.errorStackTrace ? item.errorStackTrace.split(':')[0] : 'Unknown Error') + ')'
                  }}
                </td>
              </tr>
            </ng-template>
            <ng-template #expandedrow let-item>
              <tr>
                <td colspan="4">
                  @if(item.httpMonitorExecutionValidationResult === null) {
                          <p-card>
                            <ng-template #title>
                              <span style="color: orange;">No results found for this record!</span>
                            </ng-template>
                          </p-card>
                  } @else {
                  <div class="grid-container">
                    <div class="http-status-validation">
                      <h5>Http Status</h5>
                      <p-card>
                        <ng-template #title>
                          @if (item.responseCode === 200) {
                            <span style="color: green;">Success (200)</span>
                          } @else{
                            <span style="color: red;">Failed ({{item.responseCode}})</span>
                          }
                        </ng-template>
                        <div class="p-1">
                          Expected Code: 200
                        </div>
                      </p-card>
                      @if(item.httpMonitorExecutionValidationResult === null) {
                        <p-card>
                          <ng-template #title>
                            <span style="color: red;">Something went wrong before validation!</span>
                          </ng-template>
                        </p-card>
                      } @else {
                        <div>
                          <h5>Validations</h5>
                            @for (validationResult of item.httpMonitorExecutionValidationResult.executionValidationResults; track validationResult) {
                              <div class="mt-2 mb-1">
                                <p-card >
                                  <ng-template #title>
                                    @if(validationResult.success) {
                                      <span style="color: green;">Success</span>
                                      <i class="ml-2 pi pi-check-circle" style="color: green;"></i>
                                    } @else if (!validationResult.success || validationResult.success === undefined) {
                                      <span style="color: red;">Failed</span>
                                      <i class="ml-2 pi pi-times" style="color: red;"></i>
                                    }
                                  </ng-template>
                                  <div class="p-1">Validation:
                                    <code class="inline-code">
                                      {{ parseValidation(validationResult) }}
                                    </code>
                                  </div>
                                  <div class="p-1">Message: {{validationResult.message}}</div>
                                </p-card>
                              </div>
                            }
                        </div>
                      }
                    </div>
                    <div class="response-body">
                      <h5>Response Details</h5>
                      <pre class="json-pre">{{ logTheObj(item.responseDto) }}</pre>
                    </div>
                  </div>
                }
                </td>
              </tr>
            </ng-template>
          </p-table>
        } @else if (logsData.length == 0 && !loading) {
          <div style="text-align: center; margin-top: 4%">
            <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_814_160)">
                <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_814_160">
                  <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg></div>
            <div class=" flex justify-content-center text-2xl my-2">
              No Data Found
            </div>
            <div class="info-2 flex justify-content-center text-xl my-2">
              There is no logs yet. Everything seems to be working fine
            </div>

          </div>
        }
      </p-tabPanel>

      <p-tabPanel header="Alerts">
        <div class="statistics">
          @if (shimmerLoading) {
            <app-skeleton-table [loading]="shimmerLoading" [headers]="alertsHeader"></app-skeleton-table>
          }
          @if (alertsData.length > 0) {
            <p-table
              [value]="alertsData"
              [first]="alertsPage.number * alertsPage.size"
              stripedRows
              [paginator]="incidenceData.length >0"
              [rows]="alertsPage.size"
              [showCurrentPageReport]="incidenceData.length >0"
              [tableStyle]="{ 'min-width': '50rem' }"
              [lazy]="true"
              size="small"
              (onPage)="onAlertsPageChange($event)"
              [totalRecords]="alertsPage.totalElements"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[5,10,25,50]"
              class="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                @for (header of alertsHeader; track header){
                  <th>{{ header }}</th>
                }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr class="text-500-16px">
                  <td>
                    {{ item.createdDate | date: 'dd-MM-yyyy HH:mm' }}
                  </td>
                  <td>
                  <span
                    [ngClass]="{
                      'pending-status': item.status === 'PENDING',
                      'sent-status': item.status === 'SENT',
                      'partially-sent-status': item.status === 'PARTIALLY_SENT'
                    }"
                  >
                    {{ item.status }}
                  </span>
                  </td>
                </tr>
              </ng-template>


            </p-table>
          } @else if (alertsData.length == 0 && !loading) {
            <div style="text-align: center;">
              <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_814_160)">
                  <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_814_160">
                    <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg></div>
              <div class=" flex justify-content-center text-2xl my-2">
                No Data Found
              </div>
              <div class="info-2 flex justify-content-center text-xl my-2">
                There is no alerts. Everything seems to be working fine
              </div>

            </div>
          }
        </div>

      </p-tabPanel>
    </p-tabView>
  </div>
</div>
